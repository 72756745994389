<!-- components/Login.vue -->
<template>
  <div class="wrapper">
    <section class="hero is-primary">
      <div class="hero-body">
        <div class="titlebox">
          <h2 class="title">
            <button class="button" :class="{active: login}" @click="showLogin">Login</button>
            <button class="button" :class="{active: !login}" @click="showRegister">Register</button>
          </h2>
        </div>
      </div>
    </section>
    <section v-if="login" class="section">
      <form class="container">
        <div class="field">
          <label class="label is-large" for="email">Email</label>
          <div class="control">
            <input type="email" class="input" id="email" v-model="email" v-on:keyup="enterLogin($event)" autocomplete="on">
          </div>
        </div>
        <div class="field">
          <label class="label is-large" for="password">Passwort</label>
          <div class="control">
            <input type="password" class="input" id="password" v-model="password" v-on:keyup="enterLogin($event)"
                   autocomplete="on">
          </div>
        </div>
        <div class="control">
          <a class="button reglog is-large is-primary" id="authenticate" @click="authenticate">Login</a>
        </div>
        <div class="control">
          <a class="button reglog" id="forgotPassword" @click="redirect('ForgotPassword')">
            Passwort vergessen
          </a>
        </div>

      </form>
    </section>
    <section v-if="!login" class="section">
      <form class="container">
        <div class="field">
          <label class="label is-large" for="email">Email</label>
          <div class="control">
            <input type="email" class="input" id="emailReg" v-model="email" autocomplete="email">
          </div>
        </div>
        <div class="field">
          <label class="label is-large" for="password">Passwort</label>
          <div class="control">
            <input type="password" class="input" v-model="password" autocomplete="new-password">
          </div>
          <label class="label is-large" for="password">Passwort bestätigen</label>
          <div class="control">
            <input type="password" class="input" v-on:keyup="enterRegister($event)" v-model="passwordConfirm" autocomplete="passwordConfirm">
          </div>
        </div>

        <div class="control">
          <a class="button reglog is-large is-success" id="register" @click="register">Register</a>
        </div>

      </form>
    </section>
    <infobox v-if="infotext != null" @okayIt="hideInfobox" :infotext="infotext"/>
  </div>

</template>

<script>
  import infobox from '@/components/Infobox'
  import {register, login} from '../apiDyn'

  export default {
    name: 'login',
    components: {infobox},
    data () {
      return {
        email: '',
        password: '',
        passwordConfirm: '',
        login: true,
        infotext: null,
      }
    },
    methods: {
      redirect(comp) {
        this.$router.push({name: comp})
      },
      authenticate () {
        const self = this
        console.log('authenticate')
        login({email: this.email, password: this.password}).then(res => {
          self.$store.commit('setJwtToken', res.data.token)
          self.$router.push('/')
        }).catch( error => {
              if (error.response.status === 401) {
                console.log(error.response)
                self.infotext = "Falsche Anmeldedaten"
              }
            })

                /*.then(res => {
                this.$store.commit('setJwtToken', res.data)})
                .then(() => this.$router.push('/'))
                .catch( error => {
                if (error.response.status === 401) {
                  self.infotext = "Falsche Anmeldedaten"
                }*/

      },
      register () {
        const self = this
        if (this.email.includes('@')) {
          if (this.password === this.passwordConfirm) {
            register({email: this.email, password: this.password}).then(res => {
              if (res.data === 999) {
                self.infotext = 'Email bereits vorhanden'
              } else {
                console.log(res.data)
              }
            })
          } else {
            this.infotext = "Passwörter stimmen nicht überein"
          }
        }
        else {
          this.infotext = "Dies ist keine gültige Email Adresse"
        }
      },
      enterLogin (event) {
        var key = event.key || event.keyCode;
        if (key === 13 || key === "Enter") {
          event.preventDefault();
          document.getElementById("authenticate").click();
        }
      },
      enterRegister (event) {
        var key = event.key || event.keyCode;
        if (key === 13 || key === "Enter") {
          event.preventDefault();
          document.getElementById("register").click();
        }
      },
      showLogin () {
        this.login = true
      },
      showRegister () {
        this.login = false
      },
      hideInfobox () {
        this.infotext = null
      }
    },
    mounted () {

    }
  }
</script>
<style>
  body{
    background-color: #fff;
    width: 100%;
    margin:0;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currentlysupported by Chrome, Opera and Firefox */
  }
</style>
<style scoped>
  h2 {
    display:inline-block;
    background-color: #fff;
    padding: 5px;
  }
  .container {
    position: sticky;
    top: 5px;
    background-color: #d9f0ff;
    border: 1px solid #000;
    font-size: 20px;
    padding: 5px;
    width: auto;
    text-align: center;
    max-width: 400px;
    min-height: 300px;
    margin: 5px auto;
  }
  .field, .control {
    margin: 10px;
  }
  .button {
    padding: 10px 24px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    background-color: #fff;
    cursor: pointer;
    border: none
  }
  .button:hover{
    background-color: #d9f0ff;
  }

  .input {
    border: solid;
    border-width: 1px;
    padding: 10px 24px;
    display: inline-block;
    width: 80%;
    font-size: 16px;
    background-color: #fff;
    border-radius: 0;
    text-align: center;
  }
  .active{
    background-color: #d9f0ff;
  }
  .titlebox {
    text-align: center;
  }
  .wrapper{
    margin: 5px;
  }
  .reglog{
    background-color: aliceblue;
  }
  #forgotPassword{
    font-size: 12px;
    color: #000;
    text-decoration: none;
    position:absolute;
    bottom:0;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    margin-bottom: 10px;
    max-width: 120px;
  }


</style>
