import axios from 'axios'
import {backendURL} from '@/store'

export function authenticate (userData) {
  return axios.post(backendURL + `/urlaub/api/v1.0/login/`, userData)
}

export function register (userData) {
  return axios.post(backendURL + `/urlaub/api/v1.0/register/`, userData)
}

export function changePassword (token, userData) {
  return axios.post(backendURL + `/urlaub/api/v1.0/changePassword/`, userData, { headers: { Authorization: `Bearer: ` + token }})
}

export function getCals (token) {
  return axios.get(backendURL + '/urlaub/api/v1.0/cal',  { headers: { Authorization: `Bearer: ` + token }})
}

export function addCal (token, name) {
  return axios.post(backendURL + '/urlaub/api/v1.0/addCal', {'calName': name},  { headers: { Authorization: `Bearer: ` + token }})
}

export function addCat (token, catInfo) {
  return axios.post(backendURL + '/urlaub/api/v1.0/addCat', catInfo,  { headers: { Authorization: `Bearer: ` + token }})
}

export function changeCatName (token, payload) {
  return axios.post(backendURL + '/urlaub/api/v1.0/changeCatName', payload,  { headers: { Authorization: `Bearer: ` + token }})
}

export function changeCatRank (token, payload) {
  return axios.post(backendURL + '/urlaub/api/v1.0/changeCatRank', payload,  { headers: { Authorization: `Bearer: ` + token }})
}

export function changeCalGroupRank (token, payload) {
  return axios.post(backendURL + '/urlaub/api/v1.0/changeCalGroupRank', payload,  { headers: { Authorization: `Bearer: ` + token }})
}


export function changeCatColor (token, payload) {
  return axios.post(backendURL + '/urlaub/api/v1.0/changeCatColor', payload,  { headers: { Authorization: `Bearer: ` + token }})
}

export function deleteCat (token, catID) {
  return axios.post(backendURL + '/urlaub/api/v1.0/deleteCat', catID,  { headers: { Authorization: `Bearer: ` + token }})
}

export function addNote (token, payload) {
  return axios.post(backendURL + '/urlaub/api/v1.0/addNote', payload,  { headers: { Authorization: `Bearer: ` + token }})
}

export function getYear (token, year) {
  return axios.get(backendURL + '/urlaub/api/v1.0/days/' + year,  { headers: { Authorization: `Bearer: ` + token }})
}

export function getDays (token, year, calGroupID) {
  return axios.get(backendURL + '/urlaub/api/v1.0/sdays/' + calGroupID + '/' + year  ,  { headers: { Authorization: `Bearer: ` + token }})
}

export function getDaysFromRow (token, year, calGroupID, calID) {
  return axios.get(backendURL + '/urlaub/api/v1.0/rowDays/' + calGroupID + '/' + calID + '/' + year  ,  { headers: { Authorization: `Bearer: ` + token }})
}

export function getmDays (token, year, calGroupID) {
  return axios.get(backendURL + '/urlaub/api/v1.0/mdays/' + calGroupID + '/' + year  ,  { headers: { Authorization: `Bearer: ` + token }})
}

export function changeCatForDays(token, payload) {
  return axios.post(backendURL + `/urlaub/api/v1.0/changeCats`, payload, {headers: { Authorization: `Bearer: ` + token }})
}

export function changeCatForDaysMulti(token, payload) {
  return axios.post(backendURL + `/urlaub/api/v1.0/changeCatsMulti`, payload, {headers: { Authorization: `Bearer: ` + token }})
}

export function addCatsMM(token, payload) {
  return axios.post(backendURL + `/urlaub/api/v1.0/addCatsMM`, payload, {headers: { Authorization: `Bearer: ` + token }})
}

export function addCatsMMMulti(token, payload) {
  return axios.post(backendURL + `/urlaub/api/v1.0/addCatsMMMulti`, payload, {headers: { Authorization: `Bearer: ` + token }})
}

export function removeCatMM(token, payload) {
  return axios.post(backendURL + `/urlaub/api/v1.0/removeCatMM`, payload, {headers: { Authorization: `Bearer: ` + token }})
}

export function removeCatMMMulti(token, payload) {
  return axios.post(backendURL + `/urlaub/api/v1.0/removeCatMMMulti`, payload, {headers: { Authorization: `Bearer: ` + token }})
}

export function getCalName (calID, token) {
  return axios.get(backendURL + '/urlaub/api/v1.0/getCalName/' + calID,  { headers: { Authorization: `Bearer: ` + token }})
}

export function saveCalName(payload, token) {
  return axios.post(backendURL + `/urlaub/api/v1.0/saveCalName`, payload, {headers: { Authorization: `Bearer: ` + token }})
}

export function saveNewCal(payload, token) {
  return axios.post(backendURL + `/urlaub/api/v1.0/addRow`, payload, {headers: { Authorization: `Bearer: ` + token }})
}

export function renameCal(payload, token) {
  return axios.post(backendURL + `/urlaub/api/v1.0/renameRow`, payload, {headers: { Authorization: `Bearer: ` + token }})
}

export function getCalsFromCalGroup(calGroupID, token) {
  return axios.get(backendURL + '/urlaub/api/v1.0/calGroup/' + calGroupID,  { headers: { Authorization: `Bearer: ` + token }})
}

export function getOwners(calGroupID, token) {
  return axios.get(backendURL + '/urlaub/api/v1.0/getOwners/' + calGroupID,  { headers: { Authorization: `Bearer: ` + token }})
}

export function addOwner(payload, token) {
  return axios.post(backendURL + `/urlaub/api/v1.0/addOwner`, payload, {headers: { Authorization: `Bearer: ` + token }})
}

export function deleteOwner(payload, token) {
  return axios.post(backendURL + `/urlaub/api/v1.0/deleteOwner`, payload, {headers: { Authorization: `Bearer: ` + token }})
}

export function editOwner(payload, token) {
  return axios.post(backendURL + `/urlaub/api/v1.0/editOwner`, payload, {headers: { Authorization: `Bearer: ` + token }})
}

export function checkMail(mail, token) {
  return axios.get(backendURL + '/urlaub/api/v1.0/checkMail/' +  mail, { headers: { Authorization: `Bearer: ` + token } })
}

export function resetCats(days, token) {
  return axios.post(backendURL + '/urlaub/api/v1.0/resetCats', days, { headers: { Authorization: `Bearer: ` + token } })
}

export function deleteCalGroup(payload, token) {
  return axios.post(backendURL + '/urlaub/api/v1.0/deleteCalGroup', payload, { headers: { Authorization: `Bearer: ` + token } })
}

export function deleteCal(payload, token) {
  return axios.post(backendURL + '/urlaub/api/v1.0/deleteCal', payload, { headers: { Authorization: `Bearer: ` + token } })
}

export function deleteUser(token) {
  return axios.post(backendURL + '/urlaub/api/v1.0/deleteAccount',{}, { headers: { Authorization: `Bearer: ` + token } })
}

export function checkMailAndSend(payload) {
  return axios.get(backendURL + '/urlaub/api/v1.0/checkMailAndSend/' +  payload)
}

export function resetForgottenPassword (payload) {
  return axios.post(backendURL + `/urlaub/api/v1.0/resetPassword`, payload)
}

