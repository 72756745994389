<template>
  <div v-if="ready" class="wrapper">
    <div class="personal">
      <div class="titlebox">
        <h2 class="title">Deine Kalender</h2>
      </div>
      <div class="kalender"  v-for="calGroup in calGroups" :key="calGroup.calendarGroupId"
           @click="openCalGroup(calGroup.calendarGroupId)">
        <span class="must-be-centered">{{calGroup.name}}</span>
        <span  class="expander" >
           <span class="material-icons expand" @click.stop="expandRows(calGroup.calendarGroupId)"
                 v-if="cals[calGroup.calendarGroupId].length > 1">
             <span v-if="expandRow !== calGroup.calendarGroupId">expand_more</span>
             <span v-if="expandRow === calGroup.calendarGroupId">expand_less</span>
           </span>
      </span>
        <div class="rows must-be-centered" v-if="cals[calGroup.calendarGroupId].length > 1 &&
        expandRow === calGroup.calendarGroupId">
          <span class="row"  :key="row.id" v-for="row in cals[calGroup.calendarGroupId]"
                @click.stop="openCal(row.id, calGroup.calendarGroupId)">
            {{row.name}}
          </span>
        </div>
      </div>

      <div class="kalender" id="addCalender">
        <input class="input" v-on:keyup="enterAddCal($event)" v-model="newCalender">
        <span  class="expander" >
        <div class="button"  @click="addCal">+</div>
          </span>
      </div>
    </div>
    <div class="settings">
      <div class="titlebox">
        <h2 class="title">Einstellungen</h2>
      </div>
      <div class="kalender" @click="redirect('ChangePassword', {})">
        <span class="must-be-centered">Passwort ändern</span>
      </div>
      <div class="kalender" @click="showDeleteBox">
        <span class="must-be-centered">Account löschen</span>
      </div>
      <div class="kalender" @click="logout">
        <span class="must-be-centered">Logout</span>
      </div>
    </div>
    <DeleteUser v-if="showDelete" :deleteText="deleteText" @hideDeleteBox="hideDeleteBox">
    </DeleteUser>
    <infobox v-if="showInfo" :infotext="infotext" @okayIt="okayIt"/>
  </div>
</template>

<script>
  import {getCalOverview, createCalGroup} from '@/apiDyn';
  import DeleteUser from "./DeleteUser";
  import Infobox from "./Infobox";

  export default {
    name: 'CalOverview',
    components: {DeleteUser, Infobox},
    data () {
      return {
        newCalender: '',
        calGroups: [],
        cals: {},
        showDelete: false,
        deleteText: "Möchtest Du deinen Account und alle Deine Daten löschen?",
        infotext: "",
        showInfo: false,
        ready: false,
        expandRow: null,
        currentYear: 2023
      }
    },
    created () {
      const self = this
      var currentTime = new Date();
      this.currentYear = currentTime.getFullYear()
      if (this.$store.state.jwt === null || this.$store.state.jwt.token === '') {
        this.$router.push('login')
      }
      else {
        getCalOverview(this.$store.state.jwt).then(function(res) {
          for (let entry of res.data) {
            //if entry.sortKey.startsWith("info#cal#")
            if (entry.sortKey['S'].startsWith("info#cal#")){
              //if key cal.calenderGroup.id not this.cals
              if (!self.cals[entry.calendarGroupId['S']]){
                self.cals[entry.calendarGroupId['S']] = []
              }
              self.cals[entry.calendarGroupId['S']].push({'name': entry.name['S'],  'id': entry.sortKey['S']})
            }
            else {
              self.cals[entry.calendarGroupId['S']] = []
              self.calGroups.push({'name': entry.name['S'], 'calendarGroupId': entry.calendarGroupId['S']})
            }
          }
          self.ready = true
        }).catch(function (error) {
          console.log(error)
          self.$router.push('login')
        })
      }
    },
    computed: {
      //fucntion which checks if this.cals[calID].length > 1 and returns true if so
      sortCalGroups: {
        get() {
          return Object.entries(this.cals).map(entry => entry[1])
              .sort((a, b) => a.rank - b.rank)
        },
        // setter
        set(sortedCalGroups) {
          let i = 0
          for (const sortedCalGroup of sortedCalGroups){
            sortedCalGroup.rank = i
            i++
          }
          this.cals = sortedCalGroups
          let rankedCalGroups = sortedCalGroups.map(sortedCalGroup => Object.assign({}, sortedCalGroup))
          rankedCalGroups.forEach(group => {
            delete group.calenderGroup;
          });
          let payload = {}
          payload['rankedCalGroups'] = rankedCalGroups
          //changeCalGroupRank(this.$store.state.jwt.token, payload)
          return sortedCalGroups
        },
      }
    },
    methods: {
      addCal () {
        if (this.newCalender.length>0){
          createCalGroup(this.newCalender).then((res) => {
            this.calGroups.push({'name': this.newCalender, 'calendarGroupId': res.data.cal_grp_id})
            this.cals[res.data.cal_grp_id] = [{'name': this.newCalender, 'calendarGroupId': res.data.cal_id}]
            this.newCalender = ''
          }).catch((error) => {
            console.log(error)
          })
        }
        else {
          this.infotext = "Gebe bitte einen Namen für deinen Kalender ein"
          this.showInfo = true
        }
      },
      okayIt() {
        this.showInfo = false
      },
      enterAddCal (event) {
        var key = event.key || event.keyCode;
        if (key === 13 || key === "Enter") {
          event.preventDefault();
          this.addCal();
        }
      },
      redirect (comp, parameters) {
        this.$router.push({name: comp, params: parameters})
      },
      openCalGroup(calGrpId) {
        if (this.cals[calGrpId].length > 1) {
          this.$router.push({name: 'MultiCalDyn', params: {calGroupID: calGrpId, year: this.currentYear}})
        }
        else{
          this.$router.push({name: 'SingleKalenderDyn', params: {calGroupID: calGrpId, year: this.currentYear}})
        }
      },
      openCal(calID, calGrp) {
        calID = calID.split("#")[2]
        this.$router.push({name: 'SingleKalenderDyn', params: {calGroupID: calGrp, calID:calID, year: this.currentYear}})
      },
      logout () {
        this.$store.dispatch('logout')
        this.$router.push('login')
      },
      showDeleteBox() {
        this.showDelete = true
      },
      hideDeleteBox() {
        this.showDelete = false
      },
      expandRows(calID){
        if (this.expandRow !== calID) {
          this.expandRow = calID
        }
        else {
          this.expandRow = null
        }
      }
    }
  }
</script>
<style>
  body {
    background-color: #fff;
    width: 100%;
    margin: 0;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currentlysupported by Chrome, Opera and Firefox */
  }
</style>

<style scoped>
  h2 {
    display: inline-block;
    padding: 5px;
  }

  .kalender {
    background-color: #d9f0ff;
    font-size: 20px;
    padding: 10px;
    width: 300px;
    text-align: center;
    margin: 8px auto;
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
  }
  .kalender:hover {
    opacity: 0.8;
  }
  #addCalender {
    display: flex;
    justify-content: space-between;
  }
  #addCalender:hover{
    opacity: 1;
    cursor: auto;
  }
  .must-be-centered {
    grid-column-start: 2;
  }
  .rows {
    grid-column: 1/4;
    margin-top: 4px;
  }
  .row {
    font-size: 15px;
    display: block;
    background-color: aliceblue;
    margin: 5px auto;
    padding: 5px;
    grid-column-start: 2;
  }
  .row:hover,.expand:hover,.rows:hover {
    background-color: #d9f0ff;
    opacity: 1;
  }

  .wrapper {
    width: 100%;
    overflow: hidden; /* add this to contain floated children */
    max-width: 1200px;
    margin: auto;
    text-align: center;
  }

  .personal, .settings {
    margin: 10px 20px;
    min-width: 300px;
    text-align: center;
    vertical-align: top;
    display: inline-block;

  }

  .button {
    border: solid;
    border-width: 1px;
    padding: 7px 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    background-color: aliceblue;
    margin-left: auto;
    cursor: pointer;
  }

  .button:hover {
    background: #d9f0ff;
  }

  .input {
    border: solid;
    border-width: 1px;
    border-radius: 0;
    padding: 7px 18px;
    display: inline-block;
    font-size: 16px;
    background-color: aliceblue;
    margin-right: 10px;
    text-align: center;
    grid-column: 2;
  }

  input[type='checkbox'] {
    width: 20px;
    height: 20px;
  }

  .expander {
    text-align: right;
    grid-column: 3;
  }
  .expand {
    vertical-align: middle;
  }

</style>
