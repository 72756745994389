<template>
  <div class="centerBox">
    <span class="nameDisplay" v-if="!editName && !newCat" :style="{backgroundColor: catColor}">
          {{cat.name}}
        </span>
    <input class="nameDisplay input" v-model="catNameInit" type="text" id="newCat" ref="newCat" autocomplete="off"
           :style="{backgroundColor: catColor}" v-if="editName || newCat"/>
    <i class="material-icons" @click="reset" v-if="editName || editColor">arrow_back</i>
    <i class="material-icons" @click="changeName" v-if="editName">save</i>
    <i class="material-icons catEdits md-48" v-if="!editName && !editColor && !newCat" @click.stop="deleteCat" id="deleteCat">delete</i>
    <i class="material-icons catEdits md-48" v-if="!editName && !editColor && !newCat" @click.stop="showColorEdit" id="editCatColor">color_lens</i>
    <i class="material-icons catEdits md-48" v-if="!editName && !editColor && !newCat" @click.stop="showNameEdit" id="editCatName">edit</i>
    <i class="material-icons" v-if="editColor" @click="changeColor">save</i>
    <i class="material-icons" v-if="newCat" @click="addNewCat">save</i>
    <i class="material-icons md-48" @click.stop="closeCatEditBox">clear</i>
    <color-select v-if="newCat || editColor" :catColor="cat.color" @changedColor="updateColor"/>
  </div>
</template>
<script>
  import {mapGetters} from 'vuex'
  import ColorSelect from './colorSelect'

  export default {
    name: 'catEditBox',
    components: {ColorSelect},
    props: ["cat", "newCat"],
    data () {
      return {
        catName: null,
        editName: false,
        editColor: false,
        edit: false,
      }
    },
    computed: {
      ...mapGetters([
      ]),
      catColor:
        {
          get() {
              return this.cat.color;
          },
      },
      catNameInit:
        {
          get () {
            return this.cat.name
          },
          set (val) {
            this.$emit('updateName', val)
          }
        }
    },
    methods: {
      updateColor(color) {
        this.$emit('updateColor', color)
      },
      updateName(name) {
        this.$emit('updateName', name)
      },
      deleteCat () {
        this.$emit('deleteCat')
      },
      changeName () {
        this.$emit('changeName')
        this.editName = false
      },
      changeColor () {
        this.$emit('changeColor')
        this.editColor = false
      },
      showColorEdit () {
        this.editColor = true
        this.editName = false
      },
      showNameEdit () {
        this.editName = true
        this.editColor = false
      },
      closeCatEditBox () {
        this.editName = false
        this.editColor = false
        this.$emit('close')
      },
      reset() {
        this.editName = false
        this.editColor = false
      },
      addNewCat () {
         this.$emit("createNewCat")
      },
    }
  }
</script>

<style scoped>
  .centerBox {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 10px 5px 5px grey;
    font-size: 24px;
    padding: 15px;
    text-align: center;
    min-width: 220px;
    background: #fff;
    border: 1px solid #000;
  }

  .material-icons {
    cursor: pointer;
    margin: 10px;
  }

  .nameDisplay {
    display: block;
    text-align: center;
    font-size: 24px;
  }

  input{
    background-color: #fff;
    border: 1px solid;
    border-radius: 0;
  }

</style>
