<template>
  <div v-show='Object.keys(this.clicked).length > 0' class="dayBox">
    <div class="buttonBox">
      <span id="x" @click="resetDayBox"><i class="material-icons md-48">clear</i></span>
      <span id="lock" @click="toggleLock">
        <i v-if="lock" class="material-icons md-48">lock</i>
        <i v-else class="material-icons md-48">lock_open</i>
      </span>
      <div id="multimode" v-if="admin" @click="toggleMM">
        <span id="multiModeBox" v-if="multiMode"></span>
        <span id="normaleModeBox" v-else></span>
      </div>
    </div>
    <div class="textBox">
      <div v-if="this.clicked.length === 1" class="note">
        <div v-if="!editNote">
        <span>
        {{ clicked[0].note }}
          </span>
          <button class="noteButton" v-if="admin && !editNote" @click="editNoteToggle">
            <i class="material-icons md-48 noteButton">edit</i>
          </button>
        </div>
        <div v-if="editNote">
          <button class="noteButton" @click="editNoteToggle">
            <i class="material-icons md-48">undo</i>
          </button>
          <input class="inputNote" v-model="note"/>
          <button class="noteButton" @click="addNote"><i class="material-icons md-48">save</i></button>
        </div>
      </div>
      <div v-if="this.clicked.length === 1" class="catBox">
        <span class="count" v-for="cat in clicked[0].catID" :key="cat" :style="{'background-color': cats[cat].color}">
          {{cats[cat].name}} <span v-if="cat !=='s'" @click="removeCat(cat)">
          <i class="material-icons md-48" id="removeCatX">clear</i></span>
        </span>
      </div>
      <div id="selectedDaysText">
      {{ selectedDaysString }}
      </div>


    </div>
  </div>
</template>
<script>

import {mapActions} from 'vuex'
import {mapGetters} from 'vuex'

export default {
  name: 'dayBox',
  props: ["clicked", "lock", "multiMode", "admin", "cats"],
  data() {
    return {
      catName: '',
      catEditName: '',
      catEditColor: '',
      edit: false,
      locked: false,
      editNote: false,
      multipleUsers: false,
      tmpNote: '',
      note: ''
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapActions([]),
    selectedDaysString: function () {
      function compare(a, b) {
        if (a.order < b.order)
          return -1;
        else
          return 1;
      }

      var tmp = this.clicked
      var sorted = tmp.sort(compare);
      var selectedDaysString = ""
      var lastKey = -2
      var chain = false;
      for (var key in sorted) {
        var currentDay = sorted[key]
        if (lastKey === -2) {
          selectedDaysString += currentDay.day + "." + currentDay.month + " "
        } else {
          if (sorted[lastKey].order === currentDay.order - 1) {
            chain = true;
          } else {
            if (chain) {
              selectedDaysString += "- " + sorted[lastKey].day + "." + sorted[lastKey].month + " "
              chain = false;
            }
            selectedDaysString += ", " + currentDay.day + "." + currentDay.month + " "
          }
        }
        lastKey = key
      }
      if (chain) {
        selectedDaysString += "- " + sorted[lastKey].day + "." + sorted[lastKey].month
      }
      return selectedDaysString;
    }
  },
  methods: {
    open_edit() {
      this.edit = true
    },
    addNote() {
      this.$emit("addNote", this.note)
      this.editNote = !this.editNote
    },
    resetDayBox() {
      this.editNote = false
      this.edit = false
      this.$emit('clearClicked')
    },
    toggleLock() {
      this.$emit('toggleLock')
    },
    toggleMM() {
      this.$emit('toggleMM')
    },
    editNoteToggle() {
      this.editNote = !this.editNote
      if (this.editNote) {
        this.note = this.clicked[0].note
      } else {
        this.note = ""
      }
    },
    selectedColor: function (color) {
      return "background:color: " + color
    },
    removeCat(catID) {
      this.$emit('removeCat', catID)
    },
  }
}
</script>

<style scoped>
.dayBox {
  color: black;
  background-color: #ffffff;
  text-align: center;
  min-height: 150px;
  position: sticky;
  bottom: 20px;
  box-shadow: 5px 2.5px 2.5px grey;
  margin: 20px auto;
  font-size: 24px;
  width: 95%;
  max-width: 800px;
  border: 1px solid #000000;
  overflow: hidden;
}
.buttonBox{
  width: 10%;
  float: right;
  min-height: 150px;
  display: flex;
  justify-content: center;
}

.textBox{
  width: 90%;
  float:left;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.note{
  margin: 5px 0;
  min-height: 25px;
}

#x, #lock {
  border: none;
  background-color: inherit;
  cursor: pointer;
}

#lock {
  position: absolute;
  bottom: 0;
  padding: 5px;
}

#x {
  position: absolute;
  top: 0;
  padding: 5px;
}

#selectedDaysText {
  /*position: absolute;*/
  text-align: center;
}

#selectedDaysText {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.noteButton {
  background-color: inherit;
  border: none;
  cursor: pointer;
  vertical-align: top;
}

.inputNote {
  background-color: aliceblue;
  border: none;
  line-height: 24px;
  height: 24px;
  font-size: 18px;
  text-align: center;
  border-radius: 0;
}

#multiModeBox {
  background: linear-gradient(45deg, black 25%, white 25%, white 50%, black 50%, black 75%, white 75%);
}

#normaleModeBox, #multiModeBox{
  width: 25px;
  height: 20px;
  border: 1px solid black;
  padding: 0;
  display: inline-block;
  position: absolute;
  top: 40%;
  background-color: black;
}

.catBox {
  display: flow-root;
  /*position: absolute;
  top: 40%;*/
  width: 100%;
  height: 40%
}

.count{
  margin: 5px;
  display: inline-block;
  padding: 3px 6px;
  height: 24px;
  line-height: 24px;
  font-weight: bold;
}

#removeCatX{
  vertical-align: bottom;
  cursor: pointer;
}

#multimode {
  cursor: pointer;
  display: contents;
}
.material-icons {
  color: black
}
</style>
