<template>
  <div>
    <input type="text" class="input" v-model="user_input" v-on:keyup="enter($event)" autocomplete="on">
    <button class="button" @click="send">Send</button>
    <div class="output">{{output}}</div>
  </div>
</template>

<script>
export default {
  name: "InputAI",
  data() {
    return {
      user_input: "",
      output: "",
    };
  },
  methods: {
    enter(event) {
      if (event.key === "Enter") {
        this.send();
      }
    },
    async send() {
      if (!this.user_input.trim()) {
        this.output = "Please enter a query.";
        return;
      }

      // Prepare the prompt for the Calendar Assistant
      const prompt = {
        model: "asst_Cp3ePkPKKXclYt46WrJyu9Gb", // Replace with your custom model ID
        prompt: this.user_input,
        context: "For Calendar 2024 app."
      };

      try {
        // Call the OpenAI API with the custom model
        const response = await fetch('https://api.openai.com/v1/assistants/asst_Cp3ePkPKKXclYt46WrJyu9Gb', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': ''  // Add your API key here
          },
          body: JSON.stringify({
            prompt: prompt.prompt,
            max_tokens: 150,
            temperature: 0.7
          })
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        this.output = data.choices && data.choices.length > 0 ? data.choices[0].text : 'No response received.';
      } catch (error) {
        this.output = `An error occurred: ${error.message}`;
      }
    },
  },
}
</script>

<style scoped>
.input {
  width: 500px;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
}
.button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
.output {
  margin-top: 20px;
  font-size: 18px;
  white-space: pre-wrap;
}
</style>