<template>
  <div class="calSettingsBox">
    <h2>Einstellungen</h2>

    <h3 class="text" @click.stop="expandSetting(1)">
      <span>Namen ändern</span>
      <span class="material-icons expand" v-if="settingExpansion!==1">expand_more</span>
      <span class="material-icons expand" v-if="settingExpansion===1">expand_less</span>
    </h3>
      <div class="nameEdit" v-if="settingExpansion===1">
        <input class="inputName" :value="calName" @input="updateName"/>
        <div class="button" @click="saveNewCalName">
          <i class="material-icons" id="save">
            save
          </i>
        </div>
      </div>

    <h3 class="text" @click.stop="expandSetting(2)">
      Kalenderzeilen
      <span class="material-icons expand" v-if="settingExpansion!==2">expand_more</span>
      <span class="material-icons expand" v-if="settingExpansion===2">expand_less</span>
    </h3>
    <div class="userbox" v-if="settingExpansion===2">
      <div class="row" v-for="cal in cals" :key="cal.id">
        <span class="rowName" v-if="!editName && cal.id !== toBeEditedCal">{{cal.name}} </span>
        <input class="nameDisplay input" v-model="editCalName" type="text" autocomplete="off"
               v-if="editName && cal.id === toBeEditedCal"/>
        <span class="material-icons miniButton" v-if="!editName && cal.id !== toBeEditedCal" @click="showEditCalName(cal)">
          edit
        </span>
        <span class="material-icons miniButton" v-if="editName && cal.id === toBeEditedCal" @click="renameCal(cal)">
          save
        </span>
        <span class="material-icons miniButton" v-if="editName && cal.id === toBeEditedCal" @click="abort(cal)">
          undo
        </span>
        <span class="material-icons miniButton" v-if="!editName && cal.id !== toBeEditedCal" @click="deleteCal(cal)">
          delete
        </span>
      </div>
      <div class="inputRow" v-if="!editName">
        <input class="nameDisplay input" v-model="newCalName" type="text" id="newCalName" ref="newCal" autocomplete="off"/>
        <span class="miniButton" id="addRowB" @click="saveNewCal">+ Row</span>
      </div>
    </div>

    <h3 class="text" @click.stop="expandSetting(3)">
      Besitzer verwalten
      <span class="material-icons expand" v-if="settingExpansion!==3">expand_more</span>
      <span class="material-icons expand" v-if="settingExpansion===3">expand_less</span>
    </h3>
    <div class="userbox" v-if="settingExpansion===3">

      <div class="userlist">
        <div class="user row" :key="user.user.id" v-for="user in users">
          <span id="userName">{{ user.user.email }}</span>
            <span id="adminSelect" @click="toggleAdmin(user)">
              <span class="material-icons miniButton" v-if="user.admin" id="admin">build</span>
              <span class="material-icons miniButton" v-else id="viewer">visibility</span>
            </span>
            <span @click="deleteOwner(user.id)">
            <i class="material-icons miniButton" id="deleteUser">delete</i>
          </span>
        </div>
        <div class="inputRow">
          <input class="user input" v-model="currentUser" type="text"/>
          <div class="miniButton" @click="addUser">+ User</div>
        </div>
      </div>
    </div>

    <h3 class="text" @click.stop="expandSetting(4)">
      Kalender löschen
      <span class="material-icons expand" v-if="settingExpansion!==4">expand_more</span>
      <span class="material-icons expand" v-if="settingExpansion===4">expand_less</span>
    </h3>
    <div class="button" @click="showDeleteBox" v-if="settingExpansion===4">
      <i class="material-icons" id="delete">
        delete
      </i>
    </div>

  <br/><br/>
    <div class="button" id="end" @click="removeCalSettingsBox">
      Fertig
    </div>
    <Infobox v-if="showInfo" :infotext="infotext" :show="showInfo" @okayIt="hideInfoBox"></Infobox>
    <DeleteBox v-if="showDelete" :deleteText="deleteText" :calGroupID="calGroupID" @hideDeleteBox="hideDeleteBox">
    </DeleteBox>
  </div>
</template>

<script>
import {saveCalName, getOwners, addOwner, editOwner, deleteOwner, checkMail, getCalsFromCalGroup, renameCal,
saveNewCal, deleteCal} from '@/api'
import DeleteBox from '@/components/DeleteBox'
import Infobox from "./Infobox";

export default {
  name: 'calSettingsBox',
  components: {Infobox, DeleteBox},
  props: ['calName', 'calGroupID'],
  data() {
    return {
      users: {},
      currentUser: "",
      infotext: "",
      showInfo: false,
      deleteText: "",
      showDelete: false,
      editName: false,
      toBeEditedCal: null,
      calsChanged: false,
      editCalName: "",
      newCalName: "",
      cals: {},
      settingExpansion: false,
    }
  },
  created() {
    const self = this
    const token = this.$store.state.jwt.token
    getCalsFromCalGroup(this.calGroupID, token).then(function (res) {
      self.cals = res.data.calender
      self.catGroupID = self.cals[0].catGroupID
    })
    getOwners(self.calGroupID, token).then(res => self.users = res.data)

  },
  methods: {
    removeCalSettingsBox() {
      //check if length of this.cals > 1 and if so, go to route MultiCal (get current year)
      if (this.calsChanged){
        if (this.cals.length > 1) {
          this.$router.push({name: 'MultiCal', params: {calGroupID: this.calGroupID, year: new Date().getFullYear()}})
        }
        else {
          this.$router.push({name: 'SingleKalender', params: {calGroupID: this.calGroupID, year: new Date().getFullYear()}})
              .then(() => { this.$router.go() })
        }
      }
       else {
        this.$emit('closeCalSettings')
      }
    },
    showFeiertage() {
      this.$store.commit('showFeiertage')
      this.$store.commit('removeCalSettingsBox')
    },
    saveNewCalName() {
      var payload = {'name': this.newName, 'calGroupID': this.calGroupID}
      return saveCalName(payload, this.$store.state.jwt.token)
          .then(
              this.$emit('changeCalName', this.newName)
          )
          .catch(error => {
            console.log('Error Authenticating: ', error)
          })
    },
    updateName(e) {
      this.newName = e.target.value
    },
    showDeleteBox() {
      this.showDelete = true
      this.deleteText = "Möchtest du alle zu diesem Kalender zugehörigen Daten löschen?"
    },
    hideDeleteBox() {
      this.showDelete = false
    },
    addUser() {
      if (this.currentUser)
        return checkMail(this.currentUser, this.$store.state.jwt.token)
            .then(response => {
              if (response.data !== false) {
                var uID = response.data
                return addOwner({"userID": response.data, "calGroupID": this.calGroupID}, this.$store.state.jwt.token)
                    .then(response => {
                      //Vue.set(this.users, response.data["id"], response.data)
                      if (response.data !== null) {
                        this.users[response.data] = {
                          "email": this.currentUser, "admin": false,
                          "calGroupUserID": response.data, "id": uID
                        }
                      }
                    })
              } else {
                this.infotext = "No such Mail"
                this.showInfo = true
              }
            })
    },
    deleteOwner(id) {
      const self = this
      return deleteOwner({"userID": id, "calGroupID": this.calGroupID}, this.$store.state.jwt.token)
          .then(response => {
            if (response.data === "ok") {
              delete self.users[id]
            }
          }).catch(error => {
            this.infotext = "Hat nicht funktioniert. Einziger Owner?"
            this.showInfo = true
            console.log(error)
          })
    },
    toggleAdmin(user) {
      const payload = {"userID": user.user.id, "calGroupID": this.calGroupID}
      editOwner(payload, this.$store.state.jwt.token).then(response => {
        if (response.data === "ok") {
          user.admin = !user.admin
        }
      }).catch(error => {
        this.infotext = "Es braucht mindestens einen Admin"
        this.showInfo = true
        console.log(error)
      })


    },
    hideInfoBox() {
      this.showInfo = false
      this.infotext = ""
    },
    showEditCalName(cal) {
      this.editName = true
      this.toBeEditedCal = cal.id
      this.editCalName = cal.name
    },
    abort() {
      this.editName = false
      this.toBeEditedCal = null
      this.editCalName = ""
    },
    saveNewCal(){
      if (this.newCalName !== ""){
        var self = this
        var payload = {'calName': this.newCalName, 'calGroupID': this.calGroupID, 'catGroupID': this.catGroupID}
        return saveNewCal(payload, this.$store.state.jwt.token)
            .then(function (res) {
                  self.cals.push(res.data)
                  self.newCalName = ""
                  self.calsChanged = true
                }
            )
            .catch(error => {
              console.log('Error Authenticating: ', error)
            })
      }

    },
    renameCal(cal) {
      var self = this
      var payload = {'newCalName': this.editCalName, 'calID': cal.id}
      return renameCal(payload, this.$store.state.jwt.token)
          .then(function () {
                cal.name = self.editCalName
                self.abort()
              }
          )
          .catch(error => {
            console.log('Error Authenticating: ', error)
          })
    },
    deleteCal(cal) {
      const self = this
      return deleteCal( {"calID": cal.id, "calGroupID": this.calGroupID}, this.$store.state.jwt.token)
          .then(response => {
            if (response.data === "ok") {
              self.cals = self.cals.filter( item => item.id !== cal.id);
              self.calsChanged = true;
            }
          }).catch(error => {
            self.infotext = "Hat nicht funktioniert."
            self.showInfo = true
            console.log(error)
          })
    },
    expandSetting(setting){
      if (this.settingExpansion === setting){
        this.settingExpansion = null
      }
      else {
        this.settingExpansion = setting
      }
    }
  }
}
</script>
<style scoped>
.calSettingsBox {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 500px;
  min-height: 450px;
  background-color: white;
  text-align: center;
  box-shadow: 4px 2px 2px grey;
  border: 1px solid #000;
  max-width: 95%;
  transform: translate(-50%, -50%);
}

@media only screen and (min-width: 1200px) {
  .calSettingsBox {
  }
}

@media only screen and (max-width: 380px) {
  .calSettingsBox {
    margin-left: -47.5%;
  }
}

.nameEdit {
  height: 50px;
}

.button {
  border: none;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  background-color: aliceblue;
  cursor: pointer;
  max-height: 100%;
}

.inputName {
  background-color: aliceblue;
  border: none;
  line-height: 52px;
  height: 52px;
  font-size: 18px;
  font-weight: bold;
  vertical-align: top;
  text-align: center;
  padding: 0;
  margin-right: 10px;
}

.text {
  top: 10%;
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  line-height: 24px;
  margin-bottom: 5px;
}

.miniButton {
  border: none;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  background-color: aliceblue;
  max-width: 400px;
  cursor: pointer;
  display: inline-block;
  padding: 7px 18px;
}

.input {
  border: none;
  padding: 7px 18px;
  display: inline-block;
  font-size: 16px;
  background-color: aliceblue;
  text-align: center;
}

.userbox {
  margin: 5px 10px;
  vertical-align: middle;
  text-align: center;
}

.userlist {
  margin-top: 10px;
  text-align: center;

}

#userName{
  padding: 7px 18px;
}

.user{display: inline-block}
#userButtons {
  display: inline-block;
}

#admin {
  background-color: greenyellow;
  margin-right: 5px;
  margin-left: 5px;
}

#viewer {
  background-color: #f5b461;
  margin-right: 5px;
  margin-left: 5px;
}

#end {
  margin-bottom: 10px;
  color: #000;
  text-decoration: none;
  position:absolute;
  bottom:0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  max-width: 120px;
}
.expand {
  vertical-align: middle;
  position: absolute;
  right: 40px;
}
.nameEdit{
  transition: width 600ms ease-out, height 600ms ease-out;
}

.row{
  display: grid;
  width: 100%;
  grid-template-columns: 8fr 1fr 1fr;
  grid-gap: 10px;
  margin-top: 5px;
}
.rowName{
  display: flex;
  align-items: center;
  justify-content: center;
}
.inputRow{
  display: grid;
  width: 100%;
  grid-template-columns: 10fr 3fr;
  grid-gap: 10px;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
#newCalName{
  grid-column: 1;
}
#addRowB{
  grid-column: 2/4;
}

</style>
