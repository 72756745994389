<!-- components/Login.vue -->
<template>
  <div class="wrapper">
    <section class="section">
      <form autocomplete="off" class="container">
        <div class="field">
          <label class="label is-large">Altes Passwort</label>
          <div class="control">
            <input type="password" class="input" v-model="password" autocomplete="nope">
          </div>
        </div>
        <div class="field">
          <label class="label is-large">Neues Passwort</label>
          <div class="control">
            <input type="password" class="input" v-model="passwordNew" autocomplete="nope">
          </div>
          <label class="label is-large">Passwort bestätigen</label>
          <div class="control">
            <input type="password" class="input" v-model="passwordConfirm" autocomplete="nope">
          </div>
        </div>
        <router-link :to="{ name: 'Urlaubskalender'}">
          <div class="buttonBox">
            <button class="button reglog is-large is-success">Abbrechen</button>
          </div>
        </router-link>
        <div class="buttonBox">
          <span class="button reglog is-large is-success" @click="changePassword">Ändern</span>
        </div>
      </form>
    </section>
    <infobox v-if="showInfo" :infotext="infotext" :show="showInfo" @okayIt="hideInfoBox"/>
  </div>
</template>

<script>
import Infobox from '@/components/Infobox'
import {changePassword} from '../apiDyn'

export default {
  name: 'changePassword',
  components: {Infobox},
  data() {
    return {
      email: '',
      password: '',
      passwordNew: '',
      passwordConfirm: '',
      showInfo: false,
    }
  },
  methods: {
    redirect(comp) {
      this.$router.push({name: comp})
    },
    changePassword() {
      if (this.passwordNew === this.passwordConfirm && this.passwordNew.length > 5) {
        var payload = {'old_password': this.password, 'new_password': this.passwordNew}
        changePassword( payload)
            .then(res => function() {
              this.$store.commit('setJwtToken', res.data.token)
          }).then(() => this.$router.push('/'))
            .catch(error => {
              console.log('Error Authenticating: ', error)
              this.showInfo = true
              this.infotext = "Passwort falsch"
              throw 'Auth Error'
            })
      } else {
        this.showInfo = true
        this.infotext = "Passwort stimmt nicht überein!"
      }
    },
    hideInfoBox() {
      this.showInfo = false
      this.infotext = ""
    }
  },
}
</script>
<style>
body {
  background-color: #fff;
  width: 100%;
  margin: 0;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currentlysupported by Chrome, Opera and Firefox */
}
</style>
<style scoped>
h2 {
  display: inline-block;
  background-color: #fff;
  padding: 5px;
}

.container {
  position: sticky;
  background-color: #d9f0ff;
  box-shadow: 5px 2px 2px grey;
  font-size: 20px;
  padding: 5px;
  width: auto;
  text-align: center;
  max-width: 400px;
  margin: 30px auto;
  border: 1px solid #000;
}

.field, .control {
  margin: 10px;
}

.buttonBox {
  display: inline-block;
  margin:   5px;
}

.button {
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  background-color: #fff;
  cursor: pointer;
  border: none
}

.button:hover {
  background-color: #d9f0ff;
}

.input {
  border: solid;
  border-width: 1px;
  padding: 10px 10px;
  display: inline-block;
  width: 90%;
  font-size: 16px;
  background-color: #fff;
  border-radius: 0;
}

.active {
  background-color: #d9f0ff;
}

.titlebox {
  text-align: center;
}

.wrapper {
  /*max-width: 1200px;*/
  margin: 5px;
}

.reglog {
  background-color: aliceblue;
}
</style>
