<template>
  <div class="calSettingsBox">
    <div v-for="(cal, calID) in cals" :key="calID">
      <span v-if="!editName && calID !== toBeEditedCal">{{cal.name}} </span>
      <input class="nameDisplay input" v-model="editCalName" type="text" autocomplete="off"
                           v-if="editName && calID === toBeEditedCal"/>
      <button v-if="!editName && calID !== toBeEditedCal" @click="showEditCalName(calID)">edit</button>
      <button v-if="editName && calID === toBeEditedCal" @click="renameCal(calID)">save</button>
      <button v-if="editName && calID === toBeEditedCal" @click="abort(calID)">abort</button>
      <button v-if="!editName && calID !== toBeEditedCal" @click="deleteCal(calID)">delete</button>
    </div>
  <input class="nameDisplay input" v-model="newCalName" type="text" id="newCalName" ref="newCal" autocomplete="off"/>
  <button @click="saveNewCal">Add</button>

  </div>
</template>

<script>
import {saveCalName, saveNewCal, deleteCal, renameCal, getOwners, getCalsFromCalGroup, addOwner, editOwner,
  deleteOwner, checkMail} from '@/api'
//import DeleteBox from '@/components/DeleteBox'
//import Infobox from "./Infobox";

export default {
  name: 'calSettingsBox',
  components: {},
  props: [ 'calGroupID'],
  data() {
    return {
      users: {},
      currentUser: "",
      infotext: "",
      showInfo: false,
      deleteText: "",
      showDelete: false,
      cals: {},
      newCalName: "",
      catGroup: null,
      editName: false,
      toBeEditedCal: null,
      editCalName: ""
    }
  },
  created() {
    const self = this
    getCalsFromCalGroup(this.calGroupID, this.$store.state.jwt.token).then(function (res) {
      self.cals = res.data[0]
      console.log(res.data)
      self.catGroupID = res.data[1]
  })
    getOwners(this.calGroupID, this.$store.state.jwt.token).then(res => this.users = res.data)
  },
  methods: {
    removeCalSettingsBox() {
      this.$emit('closeCalSettings')
    },
    saveNewCal(){
      var self = this
      var payload = {'calName': this.newCalName, 'calGroupID': this.calGroupID, 'catGroupID': this.catGroupID}
      return saveNewCal(payload, this.$store.state.jwt.token)
          .then(function (res) {
                self.cals[res.data.id] = res.data
              }
          )
          .catch(error => {
            console.log('Error Authenticating: ', error)
          })
    },
    showEditCalName(calID) {
      this.editName = true
      this.toBeEditedCal = calID
      console.log(this.cals)
      this.editCalName = this.cals[calID].name
    },
    abort() {
      this.editName = false
      this.toBeEditedCal = null
      this.editCalName = ""
    },
    renameCal(calID) {
      var self = this
      var payload = {'newCalName': this.editCalName, 'calID': calID}
      return renameCal(payload, this.$store.state.jwt.token)
          .then(function (res) {
                console.log(res.data)
                self.cals[calID].name = self.editCalName
                self.abort()
              }
          )
          .catch(error => {
            console.log('Error Authenticating: ', error)
          })
    },
    saveNewCalName() {
      var payload = {'name': this.newName, 'calGroupID': this.calGroupID}
      return saveCalName(payload, this.$store.state.jwt.token)
          .then(
              this.$emit('changeCalName', this.newName)
          )
          .catch(error => {
            console.log('Error Authenticating: ', error)
          })
    },
    updateName(e) {
      this.newName = e.target.value
    },
    showDeleteBox() {
      this.showDelete = true
      this.deleteText = "Möchtest du alle zu diesem Kalender zugehörigen Daten löschen?"
    },
    hideDeleteBox() {
      this.showDelete = false
    },
    addUser() {
      if (this.currentUser)
        return checkMail(this.currentUser, this.$store.state.jwt.token)
            .then(response => {
              if (response.data !== false) {
                var uID = response.data
                return addOwner({"userID": response.data, "calGroupID": this.calGroupID}, this.$store.state.jwt.token)
                    .then(response => {
                      //Vue.set(this.users, response.data["id"], response.data)
                      if (response.data !== null) {
                        this.users[response.data] = {
                          "email": this.currentUser, "admin": false,
                          "calGroupUserID": response.data, "id": uID
                        }
                      }
                    })
              } else {
                this.infotext = "No such Mail"
                this.showInfo = true
              }
            })
    },
    deleteOwner(id) {
      const self = this
      return deleteOwner({"userID": id, "calGroupID": this.calGroupID}, this.$store.state.jwt.token)
          .then(response => {
            if (response.data === "ok") {
              console.log(self.users)
              delete self.users[id]
            }
          }).catch(error => {
            this.infotext = "Hat nicht funktioniert. Einziger Owner?"
            this.showInfo = true
            console.log(error)
          })
    },
    toggleAdmin(user) {
      const payload = {"userID": user.id, "calGroupID": this.calGroupID}
      editOwner(payload, this.$store.state.jwt.token).then(response => {
        if (response.data === "ok") {
          user.admin = !user.admin
        }
      }).catch(error => {
        this.infotext = "Es braucht mindestens einen Admin"
        this.showInfo = true
        console.log(error)
      })


    },
    hideInfoBox() {
      this.showInfo = false
      this.infotext = ""
    },
    deleteCal(calID) {
      const self = this
      return deleteCal( {"calID": calID}, this.$store.state.jwt.token)
          .then(response => {
            if (response.data === "ok") {
              delete self.cals[calID]
            }
          }).catch(error => {
            self.infotext = "Hat nicht funktioniert."
            self.showInfo = true
            console.log(error)
          })
    },
  }
}
</script>
<style scoped>
.calSettingsBox {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 450px;
  background-color: white;
  text-align: center;
  box-shadow: 10px 5px 5px grey;
  border: none;
  max-width: 95%;
  transform: translate(-50%, -50%);
}

@media only screen and (min-width: 1200px) {
  .calSettingsBox {
  }
}

@media only screen and (max-width: 380px) {
  .calSettingsBox {
    margin-left: -47.5%;
  }
}

.nameEdit {
  height: 50px;
}

.button {
  border: none;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  background-color: aliceblue;
  cursor: pointer;
  max-height: 100%;
}

.inputName {
  background-color: aliceblue;
  border: none;
  line-height: 52px;
  height: 52px;
  font-size: 18px;
  font-weight: bold;
  vertical-align: top;
  text-align: center;
  padding: 0;
  margin-right: 10px;
}

.text {
  top: 10%;
  font-size: 16px;
  padding: 10px 24px;
  margin-top: 10px;
}

.miniButton {
  border: none;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  background-color: aliceblue;
  margin: 5px;
  max-width: 400px;
  cursor: pointer;
  display: inline-block;
  padding: 7px 18px;
}

.input {
  border: none;
  padding: 7px 18px;
  display: inline-block;
  font-size: 16px;
  background-color: aliceblue;
}

.userbox {
  margin: 5px 10px;
  vertical-align: middle;
  text-align: center;
}

.userlist {
  margin-top: 10px;
  text-align: center;

}

#userName{
  padding: 7px 18px;
}

.user{display: inline-block}
#userButtons {
  display: inline-block;
}

#admin {
  background-color: greenyellow;
  margin-right: 5px;
  margin-left: 5px;
}

#viewer {
  background-color: #f5b461;
  margin-right: 5px;
  margin-left: 5px;
}

#end {
  margin-bottom: 10px;
}
</style>
