<template>
  <div id="app">
    <router-view/>
  </div>

  <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
        rel="stylesheet">
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
#app {
  font-family: 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.button{
  color: #000;
}
</style>
