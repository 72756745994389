<!-- components/Login.vue -->
<template>
  <div class="wrapper">
    <section class="section">
      <form class="container" autocomplete="off">
        <div class="field">
          <label class="label is-large">Email</label>
          <div class="control">
            <input autocomplete="false" class="input" v-model="email">
          </div>
          <label class="label is-large">Neues Passwort</label>
          <div class="control">
            <input autocomplete="false" type="password" class="input" v-model="passwordNew">
          </div>
          <label class="label is-large">Passwort bestätigen</label>
          <div class="control">
            <input autocomplete="false" type="password" class="input" v-model="passwordConfirm">
          </div>
        </div>
        <router-link :to="{ name: 'Urlaubskalender'}">
          <div class="buttonBox">
            <button class="button reglog is-large is-success">Abbrechen</button>
          </div>
        </router-link>
        <div class="buttonBox">
          <span class="button reglog is-large is-success" @click="resetPass">Absenden</span>
        </div>
      </form>
    </section>
    <infobox v-if="showInfo" @okayIt="hideInfobox" :infotext="infotext"/>
  </div>
</template>

<script>
import Infobox from '@/components/Infobox'
import {resetForgottenPassword} from '@/api'

export default {
  name: 'resetPassword',
  components: {Infobox},
  props: ['resetHash'],
  data() {
    return {
      passwordNew: '',
      passwordConfirm: '',
      email: '',
      infotext: '',
      showInfo: false,
    }
  },
  methods: {
    redirect(comp) {
      this.$router.push({name: comp})
    },
    resetPass() {
      var self = this
      if (this.passwordNew === this.passwordConfirm) {
        if (this.passwordNew.length > 5) {
          var payload = {newPassword: this.passwordNew, resetHash: this.resetHash, email: this.email}
          resetForgottenPassword(payload)
              .then(() => this.$router.push('/'))
              .catch(error => {
                if (error.response.status === 411) {
                  self.infotext = "Das hat nicht funktioniert. Code abgelaufen/bereits benutzt"
                  self.showInfo = true
                }
              })
        } else {
          this.infotext = "Passwort zu kurz. Mindestens 6 Zeichen"
          this.showInfo = true
        }
      } else {
        this.infotext = "Passwörter stimmen nicht überein"
        this.showInfo = true
      }
    },
    hideInfobox() {
      this.showInfo = false
    }
  },
}
</script>
<style>
body {
  background-color: #fff;
  width: 100%;
  margin: 0;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currentlysupported by Chrome, Opera and Firefox */
}
</style>
<style scoped>
h2 {
  display: inline-block;
  background-color: #fff;
  padding: 5px;
}

.container {
  position: sticky;
  top: 5px;
  background-color: #d9f0ff;
  box-shadow: 5px 3px 5px grey;
  font-size: 20px;
  padding: 5px;
  width: auto;
  text-align: center;
  max-width: 400px;
  margin: 5px auto;
}

.field, .control {
  margin: 10px;
}

.buttonBox {
  display: inline-block;
}

.button {
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  background-color: #fff;
  cursor: pointer;
  border: none
}

.button:hover {
  background-color: #d9f0ff;
}

.input {
  border: solid;
  border-width: 1px;
  padding: 10px 24px;
  display: inline-block;
  width: 90%;
  font-size: 16px;
  background-color: #fff;
  text-align: center;
  border-radius: 0;
}

.active {
  background-color: #d9f0ff;
}

.titlebox {
  text-align: center;
}


.reglog {
  background-color: aliceblue;
  margin: 5px;
}
</style>
