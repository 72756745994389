<template>
  <div v-if="infotext != null" class="infobox">
    <div class="text">
      {{infotext}}
    </div>
    <button class="button" @click="okayIt">OK</button>
  </div>
</template>

<script>
  export default {
    name: 'infobox',
    props: ["infotext", "show"],
    methods: {
      okayIt () {
        this.$emit("okayIt")
      }
    }
  }
</script>

<style scoped>
  .infobox {
    background-color: white;
    text-align: center;
    box-shadow: 5px 2px 2px grey;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #000;
  }

  .button {
    padding: 10px 24px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    background-color: #d9f0ff;
    margin-bottom: 10px;
    cursor: pointer;
    border: none;
  }
  .button:hover {
    background: #f0f8ff;
  }
  .text {
    top: 10%;
    font-size: 16px;
    padding: 10px 24px;
    margin-top: 10px;
  }
</style>
