<template>
  <div class="counters">
    <draggable v-model="sortCats">
      <transition-group>
        <div class="count" v-for="cat in sortCats" v-bind:id="cat['id']"
             v-bind:key="cat.id"
             :style="{'background-color':cat.color}" v-on:click="click_on_cat(cat.id)" @touchstart.prevent="handleTouchStart($event)"
             @touchend.prevent="handleTouchEnd($event, cat.id)">
          <span>{{ cat['name'] }}<span v-if="cat.name !== ''">: </span>
            <span>
              {{ cat.count }}
            </span>
          </span>
          <i class="material-icons" v-show="selectedCat === cat.id" @click.stop="showCatEdit">settings_applications</i>
        </div>
      </transition-group>
    </draggable>
    <span @click="openAddCat" v-if="admin"> <i id="plus" class="material-icons">add</i></span>
  </div>
</template>

<script>
import { VueDraggableNext } from 'vue-draggable-next';

export default {
  name: 'catBox',
  props: ['cats', 'clickedLength', 'admin'],
  components: {
    draggable: VueDraggableNext,
  },
  data() {
    return {
      cat_box: false,
      edit_box: false,
      catName: '',
      catColor: '',
      selectedCat: null,
      touchStartX: 0,
      touchStartY: 0,
    };
  },
  computed: {
    length: function () {
      return this.clickedLength;
    },
    sortCats: {
      get() {
        return Object.entries(this.cats)
            .map((entry) => entry[1])
            .sort((a, b) => a.rank - b.rank);
      },
      // setter
      set(newValue) {
        // Note: we are using destructuring assignment syntax here.
        return this.$emit('changeOrder', newValue);
      },
    },
  },
  methods: {
    handleTouchStart(event) {
      this.touchStartX = event.changedTouches[0].clientX;
      this.touchStartY = event.changedTouches[0].clientY;
    },
    handleTouchEnd(event, catID) {
      const touchEndX = event.changedTouches[0].clientX;
      const touchEndY = event.changedTouches[0].clientY;

      // Calculate distance between start and end touch positions
      const deltaX = Math.abs(touchEndX - this.touchStartX);
      const deltaY = Math.abs(touchEndY - this.touchStartY);

      // Define a small threshold for detecting a tap (e.g., 10 pixels)
      const tapThreshold = 10;
      if (deltaX < tapThreshold && deltaY < tapThreshold) {
        // If the touchend coordinates are close to the touchstart coordinates, it's considered a tap
        this.click_on_cat(catID);
      }

    },
    closeAddCat() {
      this.$store.commit('setBorder', false);
    },
    openAddCat() {
      this.$emit('newCat');
    },
    click_on_cat(catID) {
      if (this.admin) {
        if (this.length > 0) {
          this.$emit('setCat', catID);
        } else {
          if (catID !== this.selectedCat && catID !== 's') {
            this.selectedCat = catID;
          } else {
            this.selectedCat = null;
          }
        }
      }
      document.activeElement.blur();
    },
    showCatEdit() {
      this.$emit('catEdit', this.selectedCat);
    },
  },
};
</script>

<style scoped>
.count {
  margin: 5px;
  display: block;
  float: left;
  padding: 3px 6px;
  cursor: pointer;
  height: 24px;
  line-height: 24px;
  font-weight: bold;
  border: 1px solid;
  min-width: 24px;
}

.counters {
  display: flow-root;
  position: sticky;
  background-color: #fff;
  top: 5px;
  font-size: inherit;
  border: 1px solid #000;
  max-width: 2000px;
  width: 98%;
  margin: 0 auto;
  z-index: 2;
}

.material-icons {
  cursor: pointer;
  vertical-align: top;
}
#plus {
  padding: 3px;
  margin: 5px;
  float: left;
}
.count, draggable, transition-group {
  touch-action: manipulation; /* Allows only intended interactions like tapping or dragging */
}
</style>
