<template>
  <div class="calSettingsBox">
    <h2>Einstellungen</h2>

    <h3 class="text" @click.stop="expandSetting(1)">
      <span>Namen ändern</span>
      <span class="material-icons expand" v-if="settingExpansion!==1">expand_more</span>
      <span class="material-icons expand" v-if="settingExpansion===1">expand_less</span>
    </h3>
      <div class="nameEdit" v-if="settingExpansion===1">
        <input class="inputName" :value="calName" @input="updateName"/>
        <div class="button" @click="saveNewCalGroupName">
          <i class="material-icons" id="save">
            save
          </i>
        </div>
      </div>

    <h3 class="text" @click.stop="expandSetting(2)">
      Kalenderzeilen
      <span class="material-icons expand" v-if="settingExpansion!==2">expand_more</span>
      <span class="material-icons expand" v-if="settingExpansion===2">expand_less</span>
    </h3>
    <div class="userbox" v-if="settingExpansion===2">
      <div class="row" v-for="cal in cals" :key="cal.id">
        <span class="rowName" v-if="!editName && cal.id !== toBeEditedCal">{{cal.name}} </span>
        <input class="nameDisplay input" v-model="editCalName" type="text" autocomplete="off"
               v-if="editName && cal.id === toBeEditedCal"/>
        <span class="material-icons miniButton" v-if="!editName && cal.id !== toBeEditedCal" @click="showEditCalName(cal)">
          edit
        </span>
        <span class="material-icons miniButton" v-if="editName && cal.id === toBeEditedCal" @click="renameCal(cal)">
          save
        </span>
        <span class="material-icons miniButton" v-if="editName && cal.id === toBeEditedCal" @click="abort(cal)">
          undo
        </span>
        <span class="material-icons miniButton" v-if="!editName && cal.id !== toBeEditedCal" @click="deleteCal(cal)">
          delete
        </span>
      </div>
      <div class="inputRow" v-if="!editName">
        <input class="nameDisplay input" v-model="newCalName" type="text" id="newCalName" ref="newCal" autocomplete="off"/>
        <span class="miniButton" id="addRowB" @click="saveNewCal">+ Row</span>
      </div>
    </div>
    <h3 class="text" @click.stop="expandSetting(3)">
      Besitzer verwalten
      <span class="material-icons expand" v-if="settingExpansion!==3">expand_more</span>
      <span class="material-icons expand" v-if="settingExpansion===3">expand_less</span>
    </h3>
    <div class="userbox" v-if="settingExpansion===3">

      <div class="userlist">
        <div class="user row" :key="user.pk['S']" v-for="user in users">
          <span id="userName">{{ user.email['S'] }}</span>
          <span @click="deleteOwner(user.pk['S'])">
            <i class="material-icons miniButton" id="deleteUser">delete</i>
          </span>
        </div>
        <div class="inputRow">
          <input class="user input" v-model="currentUser" type="text"/>
          <div class="miniButton" @click="addUser">+ User</div>
        </div>
      </div>
    </div>

    <h3 class="text" @click.stop="expandSetting(4)">
      Kalender löschen
      <span class="material-icons expand" v-if="settingExpansion!==4">expand_more</span>
      <span class="material-icons expand" v-if="settingExpansion===4">expand_less</span>
    </h3>
    <div class="button" @click="showDeleteBox" v-if="settingExpansion===4">
      <i class="material-icons" id="delete">
        delete
      </i>
    </div>

  <br/><br/>
    <div class="button" id="end" @click="removeCalSettingsBox">
      Fertig
    </div>
    <Infobox v-if="showInfo" :infotext="infotext" :show="showInfo" @okayIt="hideInfoBox"></Infobox>
    <DeleteBox v-if="showDelete" :deleteText="deleteText" :calGroupID="calGroupID" @hideDeleteBox="hideDeleteBox">
    </DeleteBox>
  </div>
</template>

<script>
import {getCalGroupInfo, updateCalGroupName, updateCalName, addCal, deleteCal, getUsers} from "../apiDyn";
import DeleteBox from '../components/DeleteBox'
import Infobox from "./Infobox";

export default {
  name: 'calSettingsDyn',
  components: {Infobox, DeleteBox},
  props: ['calName', 'calGroupID'],
  data() {
    return {
      users: [],
      currentUser: "",
      infotext: "",
      showInfo: false,
      deleteText: "",
      showDelete: false,
      editName: false,
      toBeEditedCal: null,
      calsChanged: false,
      editCalName: "",
      newCalName: "",
      cals: {},
      settingExpansion: false,
    }
  },
  created() {
    const self = this
    getCalGroupInfo(this.calGroupID).then(function(res) {
      for (let entry of res.data) {
        //if entry.sortKey.startsWith("info#cal#")
        if (entry.sortKey['S'].startsWith("info#cal#")){
          //if key cal.calenderGroup.id not this.cals
          let id = entry.sortKey['S'].split("#")[2]
          self.cals[id] = {'name': entry.name['S'],  'id': id}
        }
      }
    })
  },
  methods: {
    removeCalSettingsBox() {
      //check if length of this.cals > 1 and if so, go to route MultiCal (get current year)
      console.log(this.calsChanged)
      if (this.calsChanged){
        if (Object.keys(this.cals).length > 1) {
          this.$router.push({name: 'MultiCalDyn', params: {calGroupID: this.calGroupID, year: new Date().getFullYear()}})
              .then(() => { this.$router.go() })
        }
        else {
          this.$router.push({name: 'SingleKalenderDyn', params: {calGroupID: this.calGroupID, year: new Date().getFullYear()}})
              .then(() => { this.$router.go() })
        }
      }
       else {
        this.$emit('closeCalSettings')
      }
    },
    saveNewCalGroupName() {
      if (this.newName === "") {
        this.infotext = "Bitte gib einen Namen ein."
        this.showInfo = true
      }
      else {
        var payload = {'cal_grp_name': this.newName, 'cal_grp_id': this.calGroupID}
        return updateCalGroupName(payload)
            .then(
                this.$emit('changeCalGroupName', this.newName)
            )
            .catch(error => {
              console.log('Error Authenticating: ', error)
            })
      }
    },
    updateName(e) {
      this.newName = e.target.value
    },
    showDeleteBox() {
      this.showDelete = true
      this.deleteText = "Möchtest du alle zu diesem Kalender zugehörigen Daten löschen?"
    },
    hideDeleteBox() {
      this.showDelete = false
    },

    hideInfoBox() {
      this.showInfo = false
      this.infotext = ""
    },
    showEditCalName(cal) {
      this.editName = true
      this.toBeEditedCal = cal.id
      this.editCalName = cal.name
    },
    abort() {
      this.editName = false
      this.toBeEditedCal = null
      this.editCalName = ""
    },
    saveNewCal(){
      if (this.newCalName !== ""){
        var self = this
        var payload = {'name': this.newCalName, 'cal_group_id': this.calGroupID}
        return addCal(payload)
            .then(function (res) {
                  self.cals[res.data['id']] = res.data
                  self.newCalName = ""
                  self.calsChanged = true
                }
            )
            .catch(error => {
              console.log('Error Authenticating: ', error)
            })
      }

    },
    renameCal(cal) {
      var self = this
      var payload = {'name': this.editCalName, 'cal_id': cal.id, 'cal_group_id': this.calGroupID}
      return updateCalName(payload)
          .then(function () {
              self.$emit('changeCalName', self.editCalName, cal.id)
              cal.name = self.editCalName
              self.abort()
            }
          )
          .catch(error => {
            console.log('Error Authenticating: ', error)
          })
    },
    deleteCal(cal) {
      // check if this.cals length > 1
      if (Object.keys(this.cals).length === 1) {
        this.infotext = "Du kannst den letzten Kalender nicht löschen."
        this.showInfo = true
      }
      else {
        const self = this
        return deleteCal( {"cal_id": cal.id, "cal_group_id": this.calGroupID})
            .then(function (){
                console.log(self.cals)
                // remove key cal.id from self.cals
                delete self.cals[cal.id]
                self.calsChanged = true;
            }).catch(error => {
              self.infotext = "Die Reihe konnte nicht gelöscht werden."
              self.showInfo = true
              console.log(error)
            })
      }


    },
    expandSetting(setting){
      if (setting === 3) {
        const self = this
        getUsers(this.calGroupID).then(function(res) {
          self.users = res.data
        })
      }
      if (this.settingExpansion === setting){
        this.settingExpansion = null
      }
      else {
        this.settingExpansion = setting
      }
    }
  }
}
</script>
<style scoped>
.calSettingsBox {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 500px;
  min-height: 450px;
  background-color: white;
  text-align: center;
  box-shadow: 4px 2px 2px grey;
  border: 1px solid #000;
  max-width: 95%;
  transform: translate(-50%, -50%);
}

@media only screen and (min-width: 1200px) {
  .calSettingsBox {
  }
}

@media only screen and (max-width: 380px) {
  .calSettingsBox {
    margin-left: -47.5%;
  }
}

.nameEdit {
  height: 50px;
}

.button {
  border: none;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  background-color: aliceblue;
  cursor: pointer;
  max-height: 100%;
}

.inputName {
  background-color: aliceblue;
  border: none;
  line-height: 52px;
  height: 52px;
  font-size: 18px;
  font-weight: bold;
  vertical-align: top;
  text-align: center;
  padding: 0;
  margin-right: 10px;
}

.text {
  top: 10%;
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  line-height: 24px;
  margin-bottom: 5px;
}

.miniButton {
  border: none;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  background-color: aliceblue;
  max-width: 400px;
  cursor: pointer;
  display: inline-block;
  padding: 7px 18px;
}

.input {
  border: none;
  padding: 7px 18px;
  display: inline-block;
  font-size: 16px;
  background-color: aliceblue;
  text-align: center;
}

.userbox {
  margin: 5px 10px;
  vertical-align: middle;
  text-align: center;
}

.userlist {
  margin-top: 10px;
  text-align: center;

}

#userName{
  padding: 7px 18px;
}

.user{display: inline-block}
#userButtons {
  display: inline-block;
}

#admin {
  background-color: greenyellow;
  margin-right: 5px;
  margin-left: 5px;
}

#viewer {
  background-color: #f5b461;
  margin-right: 5px;
  margin-left: 5px;
}

#end {
  margin-bottom: 10px;
  color: #000;
  text-decoration: none;
  position:absolute;
  bottom:0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  max-width: 120px;
}
.expand {
  vertical-align: middle;
  position: absolute;
  right: 40px;
}
.nameEdit{
  transition: width 600ms ease-out, height 600ms ease-out;
}

.row{
  display: grid;
  width: 100%;
  grid-template-columns: 8fr 1fr 1fr;
  grid-gap: 10px;
  margin-top: 5px;
}
.rowName{
  display: flex;
  align-items: center;
  justify-content: center;
}
.inputRow{
  display: grid;
  width: 100%;
  grid-template-columns: 10fr 3fr;
  grid-gap: 10px;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
#newCalName{
  grid-column: 1;
}
#addRowB{
  grid-column: 2/4;
}

</style>
