<!-- components/Login.vue -->
<template>
  <div class="wrapper">
    <section class="section">
      <form class="container">
        <div class="field">
          <label class="label is-large">Email</label>
          <div class="control">
            <input type="email" class="input" v-model="email">
          </div>
        </div>
        <router-link :to="{ name: 'Urlaubskalender'}">
          <div class="buttonBox">
            <button class="button reglog is-large is-success">Abbrechen</button>
          </div>
        </router-link>
        <div class="buttonBox" @click="checkForgottenMailAndSend">
          <span class="button reglog is-large is-success" >Absenden</span>
        </div>
      </form>
    </section>
    <infobox v-if="showInfo" :infotext="infotext" @okayIt="okayIt"/>
  </div>
</template>

<script>
import Infobox from '@/components/Infobox'
import {checkMailAndSend} from '@/api'

export default {
  name: 'forgotPassword',
  components: {Infobox},
  data() {
    return {
      email: '',
      infotext: '',
      showInfo: false
    }
  },
  methods: {
    redirect(comp) {
      this.$router.push({name: comp})
    },
    checkForgottenMailAndSend() {
      var self = this
      checkMailAndSend(this.email)
          .then(() => {
            console.log("hier")
            self.infotext = "Falls eine solche Mail vorhanden ist, wurde dir ein Link zum Zurücksetzen des Passworts gesendet"
            self.showInfo = true
    })
    },
    okayIt() {
      this.$router.push('/')
    }
  },
}
</script>
<style>
body {
  background-color: #fff;
  width: 100%;
  margin: 0;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currentlysupported by Chrome, Opera and Firefox */
}
</style>
<style scoped>
h2 {
  display: inline-block;
  background-color: #fff;
  padding: 5px;
}

.container {

  position: sticky;
  top: 5px;
  background-color: #d9f0ff;
  box-shadow: 5px 3px 5px grey;
  font-size: 20px;
  padding: 5px;
  width: auto;
  text-align: center;
  max-width: 400px;
  margin: 5px auto;
}

.field, .control {
  margin: 10px;
}

.buttonBox {
  display: inline-block;
}

.button {
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  background-color: #fff;
  cursor: pointer;
  border: none;
  color: #000;
}

.button:hover {
  background-color: #d9f0ff;
}

.input {
  border: solid;
  border-width: 1px;
  padding: 10px 24px;
  display: inline-block;
  width: 90%;
  font-size: 16px;
  background-color: #fff;
  border-radius: 0;
}

.reglog {
  background-color: aliceblue;
  margin: 5px
}
</style>
