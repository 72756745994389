import axios from 'axios'
import {backendURL} from '@/store'
import store from '../store';

export function register (payload) {
    return axios.post(backendURL + '/api/register', payload)
}

export function login (payload) {
    return axios.post(backendURL + '/api/login', payload)
}

export function changePassword (payload) {
    return axios.post(backendURL + '/api/changePassword', payload, { headers: { Authorization: `Bearer ${store.getters.getToken}` }})
}

export function getCalOverview(token) {
    return axios.get(backendURL + '/api/getCalOverview', { headers: { Authorization: `Bearer ${token.token}` }})
}

export function createCalGroup (name) {
  return axios.post(backendURL + '/api/createCalGroup', {'name': name}, { headers: { Authorization: `Bearer ${store.getters.getToken}` }})
}

export function getCalGroup (cal_grp_id, year) {
    return axios.get(backendURL + '/api/getCalGroup/'+ cal_grp_id + '/' + year, { headers: { Authorization: `Bearer ${store.getters.getToken}` }})
}

export function getCalData (cal_grp_id, year, cal_id) {
    return axios.get(backendURL + '/api/getCalData/'+ cal_grp_id + '/' + year + '/' + cal_id, { headers: { Authorization: `Bearer ${store.getters.getToken}` }})
}

export function addCat (payload) {
  return axios.post(backendURL + '/api/createCat', payload, { headers: { Authorization: `Bearer ${store.getters.getToken}` }})
}

export function updateBatchDaysCatSM (payload) {
  return axios.post(backendURL + '/api/updateBatchDaysCatSM', payload, { headers: { Authorization: `Bearer ${store.getters.getToken}` }})
}

export function addCatToDays (payload) {
    return axios.post(backendURL + '/api/addCatToDays', payload, { headers: { Authorization: `Bearer ${store.getters.getToken}` }})
}

export function deleteCatFromDays (payload) {
    return axios.post(backendURL + '/api/removeCatFromDays', payload, { headers: { Authorization: `Bearer ${store.getters.getToken}` }})
}

export function updateDayNote (payload) {
    return axios.post(backendURL + '/api/updateDayNote', payload, { headers: { Authorization: `Bearer ${store.getters.getToken}` }})
}

export function updateCatName (payload) {
    return axios.post(backendURL + '/api/updateCatName', payload, { headers: { Authorization: `Bearer ${store.getters.getToken}` }})
}

export function updateCatColor (payload) {
    return axios.post(backendURL + '/api/updateCatColor', payload, { headers: { Authorization: `Bearer ${store.getters.getToken}` }})
}

export function updateCatRank (payload) {
    return axios.post(backendURL + '/api/updateCatRank', payload, { headers: { Authorization: `Bearer ${store.getters.getToken}` }})
}

export function deleteCat (payload) {
    return axios.delete(backendURL + '/api/deleteCat/' + payload['cal_group_id'] + '/' + payload['cat_id'],
        { headers: { Authorization: `Bearer ${store.getters.getToken}` }})
}

export function updateCalGroupName (payload) {
    return axios.post(backendURL + '/api/updateCalGroupName', payload, { headers: { Authorization: `Bearer ${store.getters.getToken}` }})
}

export function getCalGroupInfo(cal_group_id) {
    return axios.get(backendURL + '/api/getCalGroupInfo/' + cal_group_id, { headers: { Authorization: `Bearer ${store.getters.getToken}` }})
}

export function updateCalName (payload) {
    return axios.post(backendURL + '/api/updateCalName', payload, { headers: { Authorization: `Bearer ${store.getters.getToken}` }})
}

export function  addCal (payload) {
    return axios.post(backendURL + '/api/addCal', payload, { headers: { Authorization: `Bearer ${store.getters.getToken}` }})
}

export function deleteCal (payload) {
    return axios.delete(backendURL + '/api/deleteCal/' + payload['cal_group_id'] + '/' + payload['cal_id'], { headers: { Authorization: `Bearer ${store.getters.getToken}` }})
}

export function deleteCalGroup (payload) {
    return axios.delete(backendURL + '/api/deleteCalGroup/' + payload['cal_group_id'], { headers: { Authorization: `Bearer ${store.getters.getToken}` }})
}

export function getUsers (cal_group_id) {
    return axios.get(backendURL + '/api/getUsers/' + cal_group_id, { headers: { Authorization: `Bearer ${store.getters.getToken}` }})
}