<template>
  <div class="deletebox">
    <div class="text">
      {{deleteText}}
    </div>
    <div class="button" @click="removeBox">
      Abbrechen
    </div>
    <div class="button" @click="deleteUser">
      OK
    </div>
  </div>
</template>

<script>
  import { deleteUser } from '@/api'
  export default {
    name: 'DeleteBox',
    props: ["deleteText"],
    methods: {
      redirect(link) {
        window.location.href = link
      },
      removeBox() {
        this.$emit('hideDeleteBox')
      },
      deleteUser(){
        var self = this
        deleteUser(self.$store.state.jwt.token).then(self.$router.go())
      },

    }
  }
</script>

<style scoped>
  .deletebox {
    background-color: white;
    text-align: center;
    box-shadow: 4px 2px 2px grey;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #000;

  }
  .button {
    padding: 10px 24px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    background-color: #d9f0ff;
    cursor: pointer;
    margin: 10px;
  }
  .button:hover {
    background: #f0f8ff;
  }
  .text {
    top: 10%;
    font-size: 16px;
    padding: 10px 24px;
    margin-top: 10px;
  }
</style>
