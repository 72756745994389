<template>
  <div class="wrapper" v-if="ready">
    <h1 class="jahrtitel">
      <span id="iconNavigation">
        <i class="material-icons" id="home" @click="redirect2('/#/calOverview')">
          home
        </i>
        <i class="material-icons" v-if="admin" id="settings" @click="showCalSettingsBox">
          settings
        </i>
        <div id="view" @click="changeView"><span v-if="weekView">M</span><span v-else>W</span></div>
      </span>
      <span class="calGrpName">
        {{calName}}
      </span>

      <span class="year">
          <i v-if="year !== 2021" class="material-icons arrow"
             @click="changeYear(false)">keyboard_arrow_left</i>
          {{ year }}
          <i v-if="year !== borderyears[1]" class="material-icons arrow"
             @click="changeYear(true)">keyboard_arrow_right</i>
      </span>
    </h1>
    <multi-cat-box :cats="cats" :admin="admin" :clickedLength="clickedLength" :catGroupID="catGroupID" :count="count"
                   @newCat="openCatEditNew" @catEdit="openCatEdit" @changeOrder="changeOrder"
             @setCat="setCat"></multi-cat-box>
    <div v-if="weekView">
      <div :key="index" v-for="(month,index) in months_en" style="margin-top: 10px">
        <div class="monatstitel" :id="index" v-bind:style="{ 'grid-row': 1, 'grid-column':'1/9'}"
             :class="{ 'currentDate': (index === currentMonth && year === currentYear)}" @click="toggleActive(index)">
          {{ month }}
        </div>
        <div class="grid" v-show="activeMonths.includes(index)">
          <div class="wochentag" style="grid-row: 2; grid-column: 2">Mo</div>
          <div class="wochentag" style="grid-row: 2; grid-column: 3">Di</div>
          <div class="wochentag" style="grid-row: 2; grid-column: 4">Mi</div>
          <div class="wochentag" style="grid-row: 2; grid-column: 5">Do</div>
          <div class="wochentag" style="grid-row: 2; grid-column: 6">Fr</div>
          <div class="wochentag" style="grid-row: 2; grid-column: 7">Sa</div>
          <div class="wochentag" style="grid-row: 2; grid-column: 8">So</div>
          <template v-for="(calender, calID, jindex) in cals" :key="jindex">
            <template v-for="day in calender[index]" :key="day.id">
              <div class="datum"
                   :class="{ 'currentDate': (day.day===currentDay && day.month-1 === currentMonth && year === currentYear)}"
                   :style="{'grid-column': weekday[day.weekday], 'grid-row': getRow(weekday[day.weekday],
                 day.day, amountRows)+(3)}">
                {{ day.day }}
              </div>
              <div class="kw" @click="toggleActiveWeeks(getKW(day.year, day.month, day.day))" :key="day.id"
                   v-if="(weekday[day.weekday]===2 || day.day === 1)"
                   :style="{'grid-column': 1, 'grid-row': getRow(weekday[day.weekday], day.day, amountRows)+(3)}">
                KW{{ getKW(day.year, day.month, day.day) }}
              </div>
            </template>
            <div v-for="j in getWeekAmount(calender[index])" class="calender" :key="j" @click="getCatCount(calID)"
                 v-bind:style="[{'grid-row': 4+jindex+((amountRows+1)*(j-1)), 'grid-column': 1},
                 clickedCal === calID ? {'opacity': '50%'} : {}]">
              {{calData[calID].name}}
              <i class="material-icons" id="singleRowLink" v-if="clickedCal === calID" @click="openCalRow(calID)">
                open_in_new
              </i>
            </div>
            <div class="tagrahmen"
                 v-for="day in calender[index]" :id="day.id" :calID="calID" :key="day.id+'u'+jindex"
                 @click="mouse_on_day($event)"
                 :style="[{'background': getBackground(day.catID, calID)},
               day.clicked ? {'border-color': 'black'} : {'border-color': 'lightgrey'},
               {'grid-column': weekday[day.weekday], 'grid-row': getRow(weekday[day.weekday], day.day,
               amountRows)+4+jindex}]">
            </div>
          </template>
      </div>
    </div>
    </div>

    <div v-if="!weekView">
      <div class="gridMonth" v-for="(month,index) in months_en" :key="index">
        <div class="monatstitel" :id="index" v-bind:style="{ 'grid-row': '1/3'}"
             :class="{ 'currentDate': index === currentMonth && year === currentYear}" @click="toggleActive(index)">
          {{month}}
        </div>
        <template v-for="(calender, calID, jindex) in cals" :key="jindex" >
          <template  v-for="day in calender[index]" :key="day.id">
            <template v-if="jindex===0">
            <div  class="datum" :class="{ 'currentDate': (day.day===currentDay && day.month-1 === currentMonth && year === currentYear)}"
                  :style="{'grid-column': day.day +1, 'grid-row': jindex+1}">
              {{ day.day }}
            </div>
            <div  class="weekday" :class="{ 'currentDate': (day.day===currentDay && day.month-1 === currentMonth && year === currentYear)}"
                  :style="{'grid-column': day.day +1, 'grid-row': jindex+2}">
              {{wochentagAbk[day.weekday]}}
            </div>
              </template>
          </template>
          <div class="calender" v-show="activeMonths.includes(index)" @click="getCatCount(calID)"
               v-bind:style="[{'grid-row': jindex+3, 'grid-column': 1}, clickedCal === calID ? {'opacity': '50%'} : {}]">
            {{calData[calID].name}}
            <i class="material-icons" id="singleRowLink" v-if="clickedCal === calID" @click="openCalRow(calID)">
            open_in_new
            </i>
          </div>
          <div  class="tagrahmen" v-show="activeMonths.includes(index)"
                v-for="day in calender[index]" :id="day.id"
                :key="day.id"
                @click="mouse_on_day($event)" :calID="calID"
                :style="[{'background': getBackground(day.catID, calID)},
               day.clicked ? {'border-color': 'black'} : {'border-color': 'lightgrey'},
               {'grid-column': day.day+1, 'grid-row': jindex+3}]">
          </div>

        </template>

      </div>
    </div>
    <CatEditBox v-if="showCatEdit" :cat="catEdit" :newCat="newCat" @changeName="saveCatName" @changeColor="saveCatColor"
                @updateColor="updateColor" @updateName="updateName" @createNewCat="createNewCat"
                @deleteCat="removeCat" @close="hideCatEdit"/>
    <MultiDayBox v-if="clickedLength > 0" :clicked="clicked" :lock="locked" :admin="admin" :multiMode="multiMode"
            :cats="cats" :calData="calData"
            @clearClicked="clearClicked" @toggleLock="toggleLock" @removeCat="removeCatFromClicked"
            @addNote="addNotes" @toggleMM="toggleMultiMode"/>
    <CalSettings v-if="showCalSettings" :calGroupID="calGroupID" :calName="calName" @changeCalName="changeCalName"
                 @closeCalSettings="closeCalSettingsBox"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import { getmDays, addCatsMMMulti, removeCatMMMulti, addNote, changeCatForDaysMulti, addCat, deleteCat,
  changeCatName, changeCatColor, changeCatRank} from '@/api'
import MultiCatBox from "./MultiCatBox";
import CatEditBox from "./CatEditBox";
import MultiDayBox from "./MultiDayBox";
import CalSettings from "./CalSettings";
import t2022 from "@/assets/years/2022.json";
import t2023 from "@/assets/years/2023.json";
import t2024 from "@/assets/years/2024.json";
import t2025 from "@/assets/years/2025.json";

const years = {
  2022: t2022,
  2023: t2023,
  2024: t2024,
  2025: t2025
};




Date.prototype.getWeek = function () {
  var date = new Date(this.getTime())
  date.setHours(0, 0, 0, 0)
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7)
  // January 4 is always in week 1.
  var week1 = new Date(date.getFullYear(), 0, 4)
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
      - 3 + (week1.getDay() + 6) % 7) / 7)
}
export default {
  name: 'MultiCal',
  props: ['calGroupID', 'year'],
  components: {MultiCatBox, MultiDayBox, CatEditBox, CalSettings},
  data() {
    return {
      calName: '',
      borderyears: [2022, 2025],
      months_en: [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember'
      ],
      weekday: {
        0: 2,
        1: 3,
        2: 4,
        3: 5,
        4: 6,
        5: 7,
        6: 8
      },
      wochentagAbk: {
        0: "MO",
        1: "DI",
        2: "MI",
        3: "DO",
        4: "FR",
        5: "SA",
        6: "SO"
      },
      activeMonths: [],
      activeWeeks: [],
      currentMonth: null,
      currentDay: null,
      currentYear: null,
      weekView: true,
      cals: {},
      calData: {},
      ready: false,
      dayIndex: {},
      catGroupID: null,
      cats: {},
      clicked: {},
      clickedIDs: {},
      locked: false,
      admin: false,
      multiMode: false,
      showCatEdit: false,
      newCat: false,
      catEdit: null,
      showCalSettings: false,
      count: {},
      clickedCal: null,
    }

  },

  created() {
    var currentTime = new Date()
    this.activeMonths.push(currentTime.getMonth())
    this.currentMonth = currentTime.getMonth()
    this.currentDay = currentTime.getDate()
    this.currentYear = currentTime.getFullYear().toString()
    this.activeWeeks.push(currentTime.getWeek())
    if(window.innerWidth>700){
      this.weekView = false
    }
    this.initCal(this.year)
  },
  computed: {
    ...mapGetters([]),
    clickedLength: function () {
      return Object.keys(this.clickedIDs).length
    },
    amountRows: function () {
      return Object.keys(this.calData).length
    },
  },
  methods: {
    redirect2(link) {
      window.location.href = link
    },
    initCal(jahr) {
      const self = this
      self.days = years[jahr];
      getmDays(self.$store.state.jwt.token, jahr, self.calGroupID)
          .then(function (res2) {
            self.calData = res2.data.cals
            for (const list of res2.data.calenderDayList) {
              self.cals[list.id] = self.insertUserdays(list.calday)
              self.mapDays(list.id)
            }
            self.catGroupID = Object.keys(res2.data.cats)[0]
            res2.data.cats[self.catGroupID].forEach(item => {
              self.cats[item.id] = item
            });
            self.cats['s'] = {"id": 's',
              "name": '',
              "color": '#fff',
              "catGroupId": self.catGroupID,
              "rank": 0
            }
            self.calName = res2.data.calGroup.name
            self.admin = res2.data.admin
            self.ready = true
          })
    },
    insertUserdays(userdays) {
      let days = JSON.parse(JSON.stringify(this.days))
      for (var userday of userdays) {
        days[userday.day.month - 1][userday.day.day - 1].catID = userday.catIDs
        days[userday.day.month - 1][userday.day.day - 1].userday = userday.id
        days[userday.day.month - 1][userday.day.day - 1].note = userday.note
        days[userday.day.month - 1][userday.day.day - 1].calID = userday.calID
      }
      return days
    },
    mapDays(calID) {
      this.dayIndex[calID] = {}
      for (var month of this.cals[calID]) {
        for (var day of month) {
          this.mapDay(calID, day.id, day)
        }
      }
    },
    getCatCount(calID) {
      if (this.clickedCal === calID) {
        this.clickedCal = null
        this.count = {}
      }
      else {
        for (const cat of Object.values(this.cats)){
          this.count[cat.id] = 0
        }
        for (const month of this.cals[calID]) {
          for (const day of month) {
            for (const cat2 of day.catID) {
              this.count[cat2] += 1
            }
          }
        }
        this.clickedCal = calID
      }
    },
    changeOrder(sortedCats){
      var i = 1
      for (const sortedCat of sortedCats){
        this.cats[sortedCat.id].rank = i
        sortedCat.rank = i
        i++
      }
      var payload = {}
      payload['rankedCats'] = sortedCats
      payload['calGroupID'] = this.calGroupID
      changeCatRank(this.$store.state.jwt.token, payload)
      return  sortedCats
    },
    mapDay(calID, dayID, dayDict) {
      this.dayIndex[calID][dayID] = dayDict
    },
    getBackground(catID) {
      const catIDsLength = catID.length
      if (catIDsLength === 1) {
        if (!(catID in this.cats)) {
          catID = 's'
        }
        return this.cats[catID].color
      } else {
        catID.sort() //bisher nur nach ABC sortiert
        const step = 100 / catIDsLength
        let mark = step
        var gradString = `linear-gradient(45deg, ${this.cats[catID[0]].color} ${mark}%, `
        for (var i = 1; i < catIDsLength - 1; i++) {
          var newString = ` ${this.cats[catID[i]].color} ${mark}%, `
          mark = mark + step
          var newString2 = ` ${this.cats[catID[i]].color} ${mark}%, `
          gradString = gradString + newString + newString2
        }
        newString = ` ${this.cats[catID[catIDsLength - 1]].color} ${mark}%) `
        gradString = gradString + newString
        return gradString
      }
    },
    changeYear(direction) {
      var yearString = null
      if (direction) {
        yearString = String(parseInt(this.year) + 1)
        this.$router.push({name: 'MultiCal', params: {calGroupID: this.calGroupID, year: yearString}})
      } else {
        yearString = String(parseInt(this.year) - 1)
        this.$router.push({name: 'MultiCal', params: {calGroupID: this.calGroupID, year: yearString}})
      }
    },
    openCalRow(calID) {
      this.$router.push({name: 'SingleRow', params: {calGroupID: this.calGroupID, calID:calID, year: this.year}})
    },
    mouse_on_day(event) {
      //check if from same calender
      const calID = event.target.attributes["calid"].nodeValue
      if (!event.ctrlKey && !event.metaKey && !this.locked) {
        this.removeAllClicked()
        this.addOneClicked(event.target.id, calID)
      } else if (!(calID in this.clickedIDs) || !this.clickedIDs[calID].includes(event.target.id)) {
          this.addOneClicked(event.target.id, calID)
      } else {
        this.removeOneClicked(event.target.id, calID)
      }
    },
    removeAllClicked() {
      for (const [key,value] of Object.entries(this.clickedIDs)){
        for (const dayID of value) {
          this.dayIndex[key][dayID].clicked = false
        }
      }
      this.clicked = {}
      this.clickedIDs = {}
    },
    removeOneClicked(dayID, calID) {
      for (let i = 0; i < this.clicked[calID].length; i++) {
        if (this.clicked[calID][i].id === dayID) {
          this.clicked[calID].splice(i, 1)
          if (this.clicked[calID].length=== 0) {
            delete this.clicked[calID];
            delete this.clickedIDs[calID]
          }
          else {
            const index = this.clickedIDs[calID].indexOf(dayID)
            this.clickedIDs[calID].splice(index, 1)
          }
          break
        }
      }
      this.dayIndex[calID][dayID].clicked = false
    },
    addOneClicked(dayID, calID) {
      if (calID in this.clickedIDs) {
        this.clicked[calID].push(this.dayIndex[calID][dayID])
        this.clickedIDs[calID].push(dayID)
      }
      else {
        this.clickedIDs[calID] = [dayID]
        this.clicked[calID] = [this.dayIndex[calID][dayID]]
      }
      this.dayIndex[calID][dayID].clicked = true
    },
    clearClicked() {
      for (const [key,value] of Object.entries(this.clickedIDs)){
        for (let i = 0; i < value.length; i++) {
          this.dayIndex[key][value[i]].clicked = false
        }
      }
      this.clickedIDs = {}
      this.clicked = {}
    },
    removeCatFromClicked(catID){
      this.setCatMM(catID)
    },
    setCatMM(catID) {
      const self = this
      var toBeEdited = {}
      //check which days need to be edited if clicked cat is not in catIDs of day
      for (const [calID, listdays] of Object.entries(this.clicked)){
        listdays.forEach(function (clickedDay) {
          if (!clickedDay.catID.includes(catID)) {
            if (calID in toBeEdited){
              toBeEdited[calID].push(clickedDay)
            }
            else {
              toBeEdited[calID] = [clickedDay]
            }
          }
        })
      }
      var payload = {}
      payload['catID'] = catID
      payload['calGroupID'] = self.calGroupID
      //add CatID to day, where it isn't set yet
      if (Object.keys(toBeEdited).length > 0){
        payload['days'] = toBeEdited
        addCatsMMMulti(self.$store.state.jwt.token, payload)
            .then(function (res) {
              for (const [key, value] of Object.entries(res.data)) {
                for (const id in value) {
                  self.dayIndex[key][id].userday = res.data[key][id].userdayID
                }
              }
            })
        for (const [calID, days] of Object.entries(toBeEdited)) {
          for (const element of days) {
            var index = 0
            for (const cid of self.dayIndex[calID][element.id].catID) {
              if (cid === 's') {
                self.dayIndex[calID][element.id].catID.splice(index, 1)
                break
              }
              index++
            }
            self.dayIndex[calID][element.id].catID.push(catID)
          }
        }
      }
      //remove CatID from all days
      else {
        payload['days'] = self.clicked
        //const self = this
        removeCatMMMulti(self.$store.state.jwt.token, payload)
        for (const calDays of Object.values(self.clicked)){
          for (const element of calDays) {
            const index = element.catID.indexOf(catID);
            if (index > -1 && element.catID.length > 1) {
              element.catID.splice(index, 1);
            }
            else {
              element.catID = ['s']
            }
          }
        }

      }
    },
    addNotes(note) {
      const self = this
      for (const [calID, calDays] of Object.entries(this.clicked)){
        const payload = {"note": note, "days": calDays, "calID": calID, "calGroupID":this.calGroupID}
        addNote(this.$store.state.jwt.token, payload)
            .then(function (res) {
              for (const id in res.data) {
                self.dayIndex[calID][id].userday = res.data[id].userday
              }
            })
        for (const day of calDays){
          self.dayIndex[calID][day.id].note = note
        }
      }
    },
    toggleMultiMode() {
      this.multiMode = !this.multiMode
    },
    toggleLock() {
      this.locked = !this.locked
    },
    setCat(catID) {
      if (!this.multiMode || catID === "s") {
        var payload = {}
        payload['catID'] = catID
        payload['calGroupID'] = this.calGroupID
        payload['days'] = this.clicked
        const self = this
        changeCatForDaysMulti(this.$store.state.jwt.token, payload)
            .then(function (res) {
              for (const [calID, cal] of Object.entries(res.data)) {
                for (const [did, val2] of Object.entries(cal)) {
                  self.dayIndex[calID][did].userday = val2.userdayID
                }
              }
            })
        for (const [calID, ids] of Object.entries(self.clickedIDs)) {
          for (const id of ids) {
            self.dayIndex[calID][id].catID = [catID]
          }
        }
      } else {
        this.setCatMM(catID)
      }
    },
    openCatEditNew(catGroupID) {
      this.newCat = true
      this.showCatEdit = true
      this.catEdit = {'name': '', 'color': 'rgb(255, 255, 255)', 'catGroupID': catGroupID}
    },
    openCatEdit(catID) {
      this.newCat = false
      this.showCatEdit = true
      const catToBeEdited = this.cats[catID]
      this.catEdit = {
        'name': catToBeEdited.name, 'color': catToBeEdited.color, 'catGroupID': catToBeEdited.catGroupID,
        'id': catID
      }
    },
    updateColor(color) {
      this.catEdit['color'] = color
    },
    updateName(name) {
      this.catEdit['name'] = name
    },
    saveCatColor() {
      const self = this
      const payload = {"catColor": this.catEdit.color, "catID": this.catEdit.id, "calGroupID": this.calGroupID}
      changeCatColor(this.$store.state.jwt.token, payload)
      self.cats[self.catEdit.id].color = self.catEdit.color
    },
    saveCatName() {
      const self = this
      const payload = {"catName": this.catEdit.name, "catID": this.catEdit.id, "calGroupID": this.calGroupID}
      changeCatName(this.$store.state.jwt.token, payload)
      self.cats[self.catEdit.id].name = self.catEdit.name
    },
    createNewCat() {
      const self = this
      const payload = {"name": this.catEdit.name, "catGroupID": self.catGroupID, "color": this.catEdit.color,
        "calGroupID": this.calGroupID}
      addCat(this.$store.state.jwt.token, payload)
          .then(function (res) {
            self.cats[res.data.id] = res.data
            self.showCatEdit = false
          })
    },
    removeCat() {
      const self = this
      deleteCat(this.$store.state.jwt.token, {"catID": this.catEdit.id})
          .then(function () {
            for (const id of Object.keys(self.calData)) {
                for (const value of Object.values(self.dayIndex[id])) {
                  let index = value.catID.indexOf(self.catEdit.id)
                  if (index !== -1) {
                    if (value.catID.length === 1) {
                      value.catID = [0]
                    } else {
                      value.catID.splice(index, 1)
                    }
                  }
              }
            }
            delete self.cats[self.catEdit.id]
            self.showCatEdit = false
          })
    },
    hideCatEdit() {
      this.showCatEdit = false
    },
    showCalSettingsBox() {
      this.showCalSettings =  !this.showCalSettings
    },
    closeCalSettingsBox() {
      this.showCalSettings = false
    },
    changeCalName(newCalName) {
      this.calName = newCalName
    },
    containsObject(obj, list) {
      var i
      for (i = 0; i < list.length; i++) {
        if (list[i] === obj) {
          return true
        }
      }
      return false
    },
    toggleActive(id) {
      if (this.activeMonths.includes(id)) {
        const index = this.activeMonths.indexOf(id)
        this.activeMonths.splice(index, 1)
      } else {
        this.activeMonths.push(id)
      }
    },
    toggleActiveWeeks(kw) {
      if (this.activeWeeks.includes(kw)) {
        const index = this.activeWeeks.indexOf(kw)
        this.activeWeeks.splice(index, 1)
      } else {
        this.activeWeeks.push(kw)
      }
    },
    getRow(weekday, date, userAmount) {
      var row = Math.floor((date) / 7)
      var modulo = (date) % 7
      var tag = weekday - 1
      if (modulo > tag) {
        row++
      }
      if (modulo == 0 && tag == 7) {
        row--
      }
      row = row * (1 + userAmount)
      return row
    },
    getFirstIndexOfWeek(j, firstdayMonth) {
      var distanceToMonday = this.weekday[firstdayMonth.weekday] - 2
      if (j - 1 === 0) {
        return 0
      } else {
        return (j - 1) * 7 - distanceToMonday
      }
    },
    getWeekAmount(month) {
      var lastDay = month.reduce((prev, current) => (+prev.day > +current.day) ? prev : current)
      var row = Math.ceil((lastDay.day) / 7)
      var modulo = (lastDay.day - 1) % 7
      var tag = (this.weekday[lastDay.weekday] - 1)
      if (modulo >= tag) {
        row++
      }
      return row
    },
    getKW(year, month, day) {
      var datum = new Date(year, month - 1, day)
      var kw = datum.getWeek()
      return (kw)
    },

    changeView() {
      this.weekView = !this.weekView
    },
  },
  watch: {
    '$route.params.year': function (year) {
      this.initCal(year)
    },
  },

}
</script>
<style>
body {
  background-color: #fff;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.selection-area {
  outline: 1px solid rgba(0, 128, 255, 0.6);
  background-color: rgba(0, 128, 255, 0.2);
}
</style>
<style scoped>
.sticky {
  position: sticky;
}
.calGrpName {
  grid-column: 2;
  margin: auto;
}
.grid {
  display: grid;
  text-align: center;
  line-height: 1.3;
  grid-auto-flow: column;
  grid-template-columns: 30% 10% 10% 10% 10% 10% 10% 10%;
  margin-top: 10px;
  border: 1px solid aliceblue;
  background: aliceblue;
}

.gridMonth {
  display: grid;
  text-align: center;
  line-height: 1.5;
  grid-auto-flow: column;
  grid-template-columns: 20% repeat(31, 1fr);
  margin-top: 20px;
  background: #f0f8ff;
}

.tagrahmen {
  cursor: pointer;
  margin: 1px;
  border: 1px solid;
  line-height: 2;
}

.tagrahmen:hover, .monatstitel:hover {
  opacity: 0.6 !important;
}

.wochentag {
  margin-top: 5px;
  margin-bottom: 5px;
}

.weekday {
  font-size: xx-small;
  background-color: #d9f0ff;
}


.monatstitel {
  background-color: #d9f0ff;
  padding: 3px;
  font-size: 18px;
  cursor: pointer;
  font-weight: bold;
}

.jahrtitel {
  display: grid;
  grid-template-columns: 1fr 3fr 3fr;
  padding: 10px;
  margin: 10px;
  justify-content: center;
}

#iconNavigation{
  grid-column: 1;
}

#home, #settings, .arrow, #view, #leave {
  cursor: pointer;
}

#home, #settings, #view {
  margin: 6px;
  display: block;
  float: left;
}

#view {
  line-height: 1;
  font-size: 24px;
}

@media only screen and (max-width: 700px) {
  #view {
    display: none;
  }
}

.calender {
  line-height: 2;
  font-weight: bold;
}


.currentDate {
  background-color: #b7e1fc !important;
}

.kw, .datum {
  line-height: 2;
  background-color: #d9f0ff;
}

.kw {
  min-width: 100px;
}


.wrapper {
  max-width: 1200px;
  text-align: center;
  padding: 8px;
  margin: 0 auto 20px;
}

.year {
  grid-column: 3;
  text-align: right;
  display: inline-block;
  margin: auto 0;
}

.material-icons {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.calender{
  cursor: pointer;
}

#singleRowLink{
  vertical-align: bottom;
}
#singleRowLink:hover{
  color: maroon;
}

</style>

