import { createRouter, createWebHistory } from 'vue-router'
import Login from '../components/Login'
import NotFound from '../components/NotFound'
import SingleKalender from "../components/SingleKalender";
import ChangePassword from "../components/ChangePassword";
import ForgotPassword from "../components/ForgotPassword";
import ResetPassword from "../components/ResetPassword";
import MultiCal from "../components/MultiCal";
import MultiCalSettings from "../components/MultiCalSettings";
import SingleRow from "../components/SingleRow";
import UnregCalender from "../components/UnregCalender";
import CalOverviewDyn from "../components/CalOverviewDyn.vue";
import SingleKalenderDyn from "../components/SingleKalenderDyn.vue";
import MultiCalDyn from "../components/MultiCalDyn.vue";
import InputAI from "../components/InputAI.vue";


const routes = [
    {
      path: '/',
      name: 'Urlaubskalender',
      component: CalOverviewDyn,
    },
    {
        path: '/scalenderDyn/:calGroupID/:year/:calID?',
        name: 'SingleKalenderDyn',
        component: SingleKalenderDyn,
        props: true
    },
    {
        path: '/mcalenderDyn/:calGroupID/:year',
        name: 'MultiCalDyn',
        component: MultiCalDyn,
        props: true
    },
    {
        path: '/scalender/:calGroupID/:year',
        name: 'SingleKalender',
        component: SingleKalender,
        props: true
    },
    {
        path: '/unregCalender/:year',
        name: 'UnregKalender',
        component: UnregCalender,
        props: true
    },
    {
        path: '/scalender/:calGroupID/:calID/:year',
        name: 'SingleRow',
        component: SingleRow,
        props: true
    },
    {
        path: '/mcalender/:calGroupID/:year',
        name: 'MultiCal',
        component: MultiCal,
        props: true
    },
    {
        path: '/mcalender/settings/:calGroupID/',
        name: 'MultiCalSettings',
        component: MultiCalSettings,
        props: true
    },

    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
        path: '/changePassword',
        name: 'ChangePassword',
        component: ChangePassword,
    },
    {
        path: '/forgotPassword',
        name: 'ForgotPassword',
        component: ForgotPassword,
    },
    {
        path: '/resetPassword/:resetHash',
        name: 'ResetPassword',
        component: ResetPassword,
        props: true
    },
    { path: '/userInput', component: InputAI},
    { path: '/404', component: NotFound },
    { path: "/:catchAll(.*)", redirect: '/404' },
  ]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  })

export default router
