<template>
  <div v-if="ready" class="wrapper">
    <h1  class="jahrtitel">
      <span class="iconsNavigation">
        <i class="material-icons" id="home" @click="redirect('/#/calOverview')">
          home
        </i>
      </span>
      <span id="calInfo">
        <span @click="openMultiCal" id="calGrpName">{{ calName }}</span>:
        {{cal.name}}
      </span>
      <span class="year">
        <i v-if="parseInt(year) !== borderyears[0]" class="material-icons"
           @click="changeYear(false)">keyboard_arrow_left</i>
          {{ year }}
        <i v-if="parseInt(year) !== borderyears[1]" class="material-icons"
           @click="changeYear(true)">keyboard_arrow_right</i>
      </span>
    </h1>
    <cat-box  :cats="cats" :admin="admin" :clickedLength="clickedLength" @newCat="openCatEditNew" @catEdit="openCatEdit"
             @setCat="setCat" @changeOrder="changeOrder"></cat-box>

    <div class="monat"  v-for="(month, index) in days" :key="index">
      <h3 ref="monat" class="monatstitel">{{ months_en[index] }}</h3>
      <div class="grid">
        <div class="wochentag" style="grid-row: 1; grid-column: 1">Mo</div>
        <div class="wochentag" style="grid-row: 1; grid-column: 2">Di</div>
        <div class="wochentag" style="grid-row: 1; grid-column: 3">Mi</div>
        <div class="wochentag" style="grid-row: 1; grid-column: 4">Do</div>
        <div class="wochentag" style="grid-row: 1; grid-column: 5">Fr</div>
        <div class="wochentag" style="grid-row: 1; grid-column: 6">Sa</div>
        <div class="wochentag" style="grid-row: 1; grid-column: 7">So</div>
        <div class="tagrahmen" @click="mouse_on_day($event)" v-on:mouseenter="selectDays($event, day.calID)"
             v-for="day in month" :key="day.id" :id="day.id"
             :class="{ 'currentDay': (day.month === currentMonth && day.day === currentDay && day.year === currentYear)}"
             :style="[{'grid-row-start':day.row+1,'grid-column-start':day.weekday+1,
             'background': getBackground(day.catID)},
             day.clicked ? {'border-color': 'black  !important'} : {'border-color': '#f1f1f1'}
              ]"
        >
          <div class="tag" v-if="day['weekday']=== 5" :month_id="day.month-1"
               v-bind:key="day.id" ref="tag" v-bind:id="day['id']" style="color: 	#800000">
            {{ day['day'] }}<span :id="day['id']" v-if="day.note!=null && day.note !==''">*</span>
          </div>
          <div class="tag" v-else-if="day['weekday'] === 6" :month_id="day.month-1"
               v-bind:key="day.id" ref="tag" v-bind:id="day['id']" :blub="day['weekday']"
               style="color: 	#400000">
            {{ day['day'] }}<span v-if="day.note!=null && day.note !==''" :id="day['id']">*</span>
          </div>
          <div class="tag" v-else :month_id="day.month-1" v-bind:key="day.id" ref="tag" v-bind:id="day['id']">
            {{ day['day'] }}<span v-if="day.note!=null && day.note !==''" :id="day['id']">*</span>
          </div>
        </div>
      </div>
    </div>
    <DayBox :clicked="clicked" :lock="locked" :admin="admin" :multiMode="multiMode" :cats="cats"
            @clearClicked="clearClicked" @toggleLock="toggleLock" @removeCat="removeCatFromClicked"
            @addNote="addNotes" @toggleMM="toggleMultiMode"/>
    <CatEditBox v-if="showCatEdit" :cat="catEdit" :newCat="newCat" @changeName="saveCatName" @changeColor="saveCatColor"
                @updateColor="updateColor" @updateName="updateName" @createNewCat="createNewCat"
                @deleteCat="removeCat" @close="hideCatEdit"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {getDaysFromRow, deleteCat, addCat, changeCatName, changeCatColor, changeCatForDays, addNote, addCatsMM,
removeCatMM, changeCatRank} from '@/api'
import CatBox from './CatBox'
import DayBox from "./DayBox";
import CatEditBox from "./CatEditBox";
import t2022 from "@/assets/years/2022.json";
import t2023 from "@/assets/years/2023.json";
import t2024 from "@/assets/years/2024.json";
import t2025 from "@/assets/years/2025.json";

const years = {
  2022: t2022,
  2023: t2023,
  2024: t2024,
  2025: t2025
};

export default {
  name: 'SingleRow',
  components: {CatEditBox, DayBox, CatBox},
  props: ['year', 'calGroupID', 'calID'],
  data() {
    return {
      first: true,
      ready: false,
      cal: null,
      days: [],
      dayIndex: {},
      cats: {},
      clicked: [],
      clickedIDs: [],
      calName: '',
      catEdit: null,
      showCatEdit: false,
      newCat: false,
      locked: false,
      admin: false,
      borderyears: [2022, 2025],
      multiMode: false,
      currentDay: null,
      currentMonth: null,
      currentYear: null,
      months_en: {
        0: 'Januar',
        1: 'Februar',
        2: 'März',
        3: 'April',
        4: 'Mai',
        5: 'Juni',
        6: 'Juli',
        7: 'August',
        8: 'September',
        9: 'Oktober',
        10: 'November',
        11: 'Dezember',
      }
    }
  },
  computed: {
    ...mapGetters([]),
    clickedLength: function () {
      return this.clickedIDs.length
    }
  },
  methods: {
    ...mapGetters([]),
    redirect(link) {
      window.location.href = link
    },
    changeYear(direction) {
      var yearString = null
      if (direction) {
        yearString = String(parseInt(this.year) + 1)
        this.$router.push({name: 'SingleRow', params: {calGroupID: this.calGroupID, year: yearString, calID: this.calID}})
      } else {
        yearString = String(parseInt(this.year) - 1)
        this.$router.push({name: 'SingleRow', params: {calGroupID: this.calGroupID, year: yearString, calID: this.calID}})
      }
    },
    toggleMultiMode() {
      this.multiMode = !this.multiMode
    },
    mouse_on_day(event) {
      if (!event.ctrlKey && !event.metaKey && !this.locked) {
        this.removeAllClicked()
        this.addOneClicked(event.target.id)
      } else if (!this.clickedIDs.includes(event.target.id)) {
        this.addOneClicked(event.target.id)
      } else {
        this.removeOneClicked(event.target.id)
      }
    },
    removeAllClicked() {
      for (const dayID of this.clickedIDs) {
        this.dayIndex[dayID].clicked = false
      }
      this.clicked = []
      this.clickedIDs = []
    },
    removeOneClicked(dayID) {
      const index = this.clickedIDs.indexOf(dayID)
      for (let i = 0; i < this.clicked.length; i++) {
        if (this.clicked[i].id === dayID) {
          this.clicked.splice(i, 1)
          break
        }
      }
      this.clickedIDs.splice(index, 1)
      this.dayIndex[dayID].clicked = false
    },
    addOneClicked(dayID) {
      this.clicked.push(this.dayIndex[dayID])
      this.clickedIDs.push(dayID)
      this.dayIndex[dayID].clicked = true
    },
    selectDays(event) {
      if (event.buttons === 1 || event.buttons === 3) {
        if (!event.ctrlKey && !event.metaKey && !this.$store.state.locked) {
          this.removeAllClicked()
          this.addOneClicked(event.target.id)
        } else if (!this.clickedIDs.includes(event.target.id)) {
          this.addOneClicked(event.target.id)
        } else {
          //this.removeOneClicked(event.target.id)
        }
      }
    },
    setCat(catID) {
      if (!this.multiMode || catID === 's') {
        var payload = {}
        if (catID === '0'){
          payload['catID'] = catID
        }
        else{
          payload['catID'] = catID
        }
        payload['calID'] = this.cal.id
        payload['calGroupID'] = this.calGroupID
        payload['days'] = this.clicked
        const self = this
        changeCatForDays(this.$store.state.jwt.token, payload)
            .then(function (res) {
              for (const id in res.data) {
                self.dayIndex[id].userday = res.data[id].userdayID
              }
            })
        for (const id of self.clickedIDs) {
          for (const cid of self.dayIndex[id].catID) {
            self.cats[cid].count -= 1
          }
          self.dayIndex[id].catID = [catID]
          self.cats[catID].count += 1
        }
      } else {
        this.setCatMM(catID)
      }
    },
    changeOrder(sortedCats){
      var i = 1
      for (const sortedCat of sortedCats){
        this.cats[sortedCat.id].rank = i
        sortedCat.rank = i
        i++
      }
      var payload = {}
      payload['rankedCats'] = sortedCats
      payload['calGroupID'] = this.calGroupID
      changeCatRank(this.$store.state.jwt.token, payload)
      return  sortedCats
    },
    setCatMM(catID) {
      const self = this
      var toBeEdited = []
      this.clicked.forEach(function (clickedDay) {
        if (clickedDay.catID.includes(catID)) {
          console.log("RemovedCatDay")
        }
        else {
          toBeEdited.push(clickedDay)
        }
      })
      if (toBeEdited.length > 0){
        var payload = {}
        payload['catID'] = catID
        payload['calID'] = self.cal.id
        payload['days'] = toBeEdited
        payload['calGroupID'] = self.calGroupID
        //const self = this
        addCatsMM(self.$store.state.jwt.token, payload)
            .then(function (res) {
              for (const id in res.data) {
                self.dayIndex[id].userday = res.data[id].userdayID
              }
            })
        for (const element of toBeEdited) {
          var index = 0
          for (const cid of self.dayIndex[element.id].catID) {
            if (cid === 's') {
              self.cats['s'].count -= 1
              self.dayIndex[element.id].catID.splice(index, 1)
              break
            }
            index++
          }
          self.dayIndex[element.id].catID.push(catID)
          self.cats[catID].count += 1
        }
      }
      else {
        payload = {}
        payload['catID'] = catID
        payload['calID'] = self.cal.id
        payload['days'] = self.clicked
        payload['calGroupID'] = self.calGroupID
        //const self = this
        removeCatMM(self.$store.state.jwt.token, payload)
        for (const element of self.clicked) {
          self.cats[catID].count -= 1
          const index = element.catID.indexOf(catID);
          if (index > -1 && element.catID.length > 1) {
            element.catID.splice(index, 1);
          }
          else {
            element.catID = ['s']
            self.cats['s'].count += 1
          }
        }
      }
    },
    removeCatFromClicked(catID){
      this.setCatMM(catID)
    },

    //catEditMethods
    openCatEditNew() {
      this.catEdit = {'name': '', 'color': 'rgb(255, 255, 255)', 'catGroupID': this.cats['s'].catGroupID}
      this.newCat = true
      this.showCatEdit = true
    },
    openCatEdit(catID) {
      this.newCat = false
      this.showCatEdit = true
      const catToBeEdited = this.cats[catID]
      this.catEdit = {
        'name': catToBeEdited.name, 'color': catToBeEdited.color, 'catGroupID': catToBeEdited.catGroupID,
        'id': catID
      }
    },
    updateColor(color) {
      this.catEdit['color'] = color
    },
    updateName(name) {
      this.catEdit['name'] = name
    },
    saveCatColor() {
      const self = this
      const payload = {"catColor": this.catEdit.color, "catID": this.catEdit.id, "calGroupID": this.cal.calenderGroupID}
      changeCatColor(this.$store.state.jwt.token, payload)
      self.cats[self.catEdit.id].color = self.catEdit.color
    },
    saveCatName() {
      const self = this
      const payload = {"catName": this.catEdit.name, "catID": this.catEdit.id, "calGroupID": this.cal.calenderGroupID}
      changeCatName(this.$store.state.jwt.token, payload)
      self.cats[self.catEdit.id].name = self.catEdit.name
    },
    createNewCat() {
      const self = this
      const payload = {"name": this.catEdit.name, "catGroupID": self.cal.catGroupID, "color": this.catEdit.color,
        "calGroupID": this.cal.calenderGroupID}
      addCat(this.$store.state.jwt.token, payload)
          .then(function (res) {
            self.cats[res.data.id] = res.data
            self.showCatEdit = false
          })
    },
    removeCat() {
      const self = this
      deleteCat(this.$store.state.jwt.token, {"catID": this.catEdit.id})
          .then(function () {
            for (const value of Object.values(self.dayIndex)) {
              let index = value.catID.indexOf(self.catEdit.id)
              if (index !== -1) {
                if (value.catID.length === 1){
                  value.catID = ['s']
                }
                else {
                  value.catID.splice(index, 1)
                }

              }
            }
            delete self.cats[self.catEdit.id]
            self.showCatEdit = false
          })
    },
    hideCatEdit() {
      this.showCatEdit = false
    },
    changeCalName(newCalName) {
      this.calName = newCalName
    },
    addNotes(note) {
      const self = this
      const payload = {"note": note, "days": this.clicked, "calID": this.cal.id, "calGroupID":this.calGroupID}
      addNote(this.$store.state.jwt.token, payload)
          .then(function (res) {
            for (const id in res.data) {
              self.dayIndex[id].userday = res.data[id].userday
            }
          })
      for (const id of self.clickedIDs) {
        self.dayIndex[id].note = note
      }
    },
    clearClicked() {
      for (const dayID of this.clickedIDs) {
        this.dayIndex[dayID].clicked = false
      }
      this.clickedIDs = []
      this.clicked = []
    },
    toggleLock() {
      this.locked = !this.locked
    },
    containsObject(obj, list) {
      var i
      for (i = 0; i < list.length; i++) {
        if (list[i] === obj) {
          return true
        }
      }
      return false
    },
    getBackground(catID) {
      const catIDsLength = catID.length
      if (catIDsLength === 1){
        if (!(catID in this.cats)) {
          catID = 's'
        }
        return this.cats[catID[0]].color
      }
      else {
        const step = 100/ catIDsLength
        var mark = step
        var gradString = `linear-gradient(45deg, ${this.cats[catID[0]].color} ${mark}%, `
        for (var i = 1; i < catIDsLength-1; i++ ) {
          var newString = ` ${this.cats[catID[i]].color} ${mark}%, `
          mark = mark + step
          var newString2 = ` ${this.cats[catID[i]].color} ${mark}%, `
          gradString = gradString + newString + newString2
        }
        newString = ` ${this.cats[catID[catIDsLength-1]].color} ${mark}%) `
        gradString = gradString + newString
        return gradString
      }
    },
    getCatCount() {
      for (var month of this.days) {
        for (var day of month) {
          for (var cat of day.catID) {
            this.cats[cat].count += 1
          }
          this.mapDays(day.id, day)
        }
      }
    },
    mapDays(dayID, dayDict) {
      this.dayIndex[dayID] = dayDict
    },
    insertUserdays(userdays) {
      for (var userday of userdays) {
        this.days[userday.day.month - 1][userday.day.day - 1].catID = userday.catIDs
        this.days[userday.day.month - 1][userday.day.day - 1].userday = userday.id
        this.days[userday.day.month - 1][userday.day.day - 1].note = userday.note
      }
    },
    initCal(jahr) {
      const self = this
      self.days = years[jahr];
      getDaysFromRow(self.$store.state.jwt.token, jahr, self.calGroupID, self.calID)
          .then(function (res2) {
            self.cal = res2.data.cal
            self.insertUserdays(res2.data.days)
            self.cats = {};
            res2.data.cats.forEach(item => {
              self.cats[item.id] = item
              item.count = 0
            });
            self.cats['s'] = {"id": 's',
              "name": '',
              "color": '#fff',
              "count": 0,
              "catGroupId": self.cal.catGroupID,
              "rank": 0
            }
            self.getCatCount()
            self.calName = res2.data.calGroup.name
            self.admin = res2.data.admin
            self.ready = true
          })
    },
    jumpToCurrentMonth(){
      var element = this.$refs["monat"][this.currentMonth-1];
      var top = element.offsetTop-200;
      window.scrollTo(0, top);
    },
    openMultiCal() {
      this.$router.push({name: 'MultiCal', params: {year: this.year, calGroupID: this.calGroupID}})
    },
  },

  created() {
    this.initCal(this.year)
    var currentTime = new Date();
    this.currentMonth = currentTime.getMonth() + 1;
    this.currentDay = currentTime.getDate();
    this.currentYear = currentTime.getFullYear()
  },

  watch: {
    '$route.params.year': function (year) {
      this.initCal(year)
    },


  },
  updated() {
    if (this.first) {
      this.first = false
      this.jumpToCurrentMonth()
    }

  }
}
</script>


<style>
.selection-area {
  outline: 1px solid rgba(0, 128, 255, 0.6);
  background-color: rgba(0, 128, 255, 0.2);
}

body {
  background-color: #fff;
  width: 100%;
  margin: 0;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
</style>
<style scoped>
.grid {
  display: grid;
  text-align: center;
  line-height: 1.3;
}

.monat {
  flex: 1;
  width: 100%;
  background: #f0f8ff;
  margin: 20px 2px;
}

@media only screen and (min-width: 961px) {
  .monat {
    max-width: 450px;
    display: inline-block;
    vertical-align: top;
    margin-right: 0.5%;
    margin-left: 0.5%;
  }
}

@media only screen and (min-width: 601px) and (max-width: 960px) {
  .monat {
    width: 49%;
    display: inline-block;
    vertical-align: top;
    margin-right: 0.5%;
    margin-left: 0.5%;
  }
}

@media only screen and (max-width: 600px) {
  .monat {
    margin: auto;
    margin-top: 20px;
  }
}

.tag {
  border: none;
}

.tagrahmen {
  cursor: pointer;
  margin: 1px;
  border: 1px #f1f1f1 solid;
  line-height: 2;
}

.tagrahmen:hover {
  opacity: 0.6 !important;
}

.edit_box_shadow {
  width: 100%;
  min-height: 10%;
  display: inline-flex;
}

.cat_button {
  width: 200px;
  margin-bottom: 3px;
  margin-top: 2px;
  margin-left: auto;
  margin-right: auto;
  min-height: 20px;
}

.edit_button {
  margin-bottom: 3px;
  margin-top: 2px;
  margin-left: auto;
  margin-right: auto;
  min-height: 20px;
}

.count {
  margin: 5px;
  display: flex;
  float: left;
  padding: 3px 6px;
  cursor: pointer;
  height: 24px;
  line-height: 24px;
  font-weight: bold;
  vertical-align: middle;
}

#home, #settings, #reset {
  margin: 6px;
  display: block;
  float: left;
  cursor: pointer;
}

.wochentag, .monatstitel {
  background-color: #d9f0ff;
  text-align: center;
  margin-top: 0;
}

.jahrtitel {
  display: grid;
  padding: 10px;
  margin: 10px auto;
  justify-content: center;
  grid-template-columns: 1fr 3fr 3fr;
  align-items: center;
  width: 95%;
  max-width: 2000px;
}

.year {
  display: inline-block;
  grid-column: 3;
  text-align: right;
  margin: auto 0;
}

.monatstitel {
  padding: 3px 10px 3px 10px;
  margin-bottom: 5px
}

.wochentag {
  margin-bottom: 3px
}

.material-icons {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

body {
  margin: 0;
}

.wrapper {
  width: 100%;
  max-width: 2000px;
  text-align: center;
  margin-bottom: 40px;
}
#calGrpName {
  cursor: pointer;
  grid-column: 2;
}
#calInfo{
  grid-column: 2;
  margin: auto;
}
.iconsNavigation {
  display: inline-flex;
  justify-content: left;
  align-items: center;
  grid-column: 1;
}
.currentDay {
  border: 1px solid red !important;
}
</style>
