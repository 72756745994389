import { createStore } from 'vuex'
import {isValidJwt} from '@/utils'

//export const backendURL = 'http://127.0.0.1:5000'

export const backendURL = 'https://lfqitb07og.execute-api.eu-central-1.amazonaws.com/dev'

const store = createStore({
    state () {
        return {
            jwt: {'token': localStorage.getItem('token')},
        }
    },
    mutations: {
        setUserData (state, payload) {
            state.userData = payload.userData
        },
        setJwtToken (state, payload) {
            console.log(payload)
            localStorage.token = payload.token
            state.jwt = payload
        },
        resetJwtToken (state) {
            localStorage.token = ''
            state.jwt = null
        }
    },
    actions: {
        logout ({commit}) {
            commit('resetJwtToken')
        }
    },
    getters: {
        isAuthenticated (state) {
            return isValidJwt(state.jwt.token)
        },
        getTest(state) {
            return state.test
        },
        getToken(state) {
            return state.jwt.token
        },
    }
})

export default store
